import React from 'react';
import {Link} from 'react-router-dom';

import {trim} from '../util/Trim';

class RecipeTile extends React.Component {    

    /**
     * Displays the time and difficulty.
     * 
     * @author Lennart Bergmann
     * @created 2020-03-10
     * 
     * @returns {JSX}
     */
    displayTimeDifficulty() {
        if(('' !== this.props.recipe.time && '0' !== this.props.recipe.time && null !== this.props.recipe.time)
        || ('' !== this.props.recipe.difficulty && null !== this.props.recipe.difficulty)) {            
            return (
                <div className="time-difficulty">
                    {this.displayTime()}
                    {'' !== this.props.recipe.time && '0' !== this.props.recipe.time && '' !== this.props.recipe.difficulty ? ' - ' : ''}
                    {this.displayDifficulty()}
                </div>
            )
        }
    }


    /**
     * Displays the time.
     * 
     * @author Lennart Bergmann
     * @created 2020-03-11
     * 
     * @returns {JSX}
     */
    displayTime() {
        if('' !== this.props.recipe.time && '0' !== this.props.recipe.time && null !== this.props.recipe.time) {
            return (
                <span className="time">
                    <i className="far fa-clock"></i> {this.props.recipe.time} Minuten
                </span>
            );
        }
    }


    /**
     * Displays the difficulty.
     * 
     * @author Lennart Bergmann
     * @created 2020-03-11
     * 
     * @returns {JSX}
     */
    displayDifficulty() {
        if('' !== this.props.recipe.difficulty && null !== this.props.recipe.difficulty) {
            const aDifficulty = {
                s : {
                    'easy' : 'Einfach', 'medium' : 'Mittel', 'hard' : 'Schwer'
                },
                i : {
                    'easy' : 1, 'medium' : 2, 'hard' : 3
                }
            };
            return (
                <span className="difficulty">
                    {/* NTH <i className="fas fa-bread-slice"></i>
                    <i className={'fas fa-bread-slice' + ( 2 > aDifficulty.i[this.props.recipe.difficulty] ? ' grey' : '' )}></i>
                    <i className={'fas fa-bread-slice' + ( 3 > aDifficulty.i[this.props.recipe.difficulty] ? ' grey' : '' )}></i> */}
                    <span className="tag">{aDifficulty.s[this.props.recipe.difficulty]}</span>
                </span>
            );
        }
    }


    /**
     * Displays the tags.
     * 
     * @author Lennart Bergmann
     * @created 2020-03-10
     * 
     * @returns {JSX}
     */
    displayTags() {
        if(this.props.recipe.tags) {
            const aTags = this.props.recipe.tags.split(',');
            return aTags.map(sTag => {
                return (<span key={sTag} onClick={this.props.changeTag} className="tag">{trim(sTag)}</span>);
            });
        }
    }
    

    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-08
     * 
     * @return {JSX}
     */
    render() {
        const oTo = {
            pathname : '/rezept/' + this.props.recipe.url,
            state : {
                recipe : this.props.recipe
            }
        };
        return (
            <div className="column is-half recipe-tile">
                <div className="card">
                    <Link to={oTo}>
                        <div className="card-image image-container">
                            <picture className="image is-16by9">
                                <source srcSet={process.env.REACT_APP_UPLOADS_URL + this.props.recipe.image + '_M.jpg'} media="(min-width: 425px)" />
                                <img src={process.env.REACT_APP_UPLOADS_URL + this.props.recipe.image + '_S.jpg'} alt={this.props.recipe.title} />
                            </picture>
                        </div>

                        <h2>{this.props.recipe.title}</h2>
                    </Link>                    

                    {this.displayTimeDifficulty()}

                    <div className="tags">
                        {this.displayTags()}
                    </div>
                </div>
            </div>
        )
    }
}

export default RecipeTile;
