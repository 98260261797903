import React from 'react';

import {log} from '../util/Log';
import {ajax} from '../util/Ajax';
import Authenticator from '../util/Authenticator';

import RoomUsers from '../partial/RoomUsers';
import Page from './Page';

/**
 * NTH display name, email
 * NTH name, email, password changeable
 */
class Settings extends React.Component {


    /**
     * Constructor.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-22
     * 
     */
    constructor(props) {
        super(props);

        this.state = {
            loading      : true,
            room         : '',
            changeRoom   : false,
            users        : [],
            admin        : false,
            error        : false,
            errorMessage : '',
        };

        this.changeRoom    = this.changeRoom.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.logout        = this.logout.bind(this);
    }


    /**
     * Component did mount.
     * 
     * @author Lennart Bergmann
     * @created 2021-01-07
     */
    componentDidMount() {
        this.getRoomsUsers();
    }


    /**
     * Get rooms users.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-23
     * 
     */
    getRoomsUsers() {

        const self = this;
        const sUrl = 'user';
        const sAction = 'get-rooms-users';

        const oFormData = new FormData();
        oFormData.append('action', sAction);

        ajax(oFormData, sUrl)
        .then(function(oResponse) {
            log(oResponse, 'Settings');
            if(oResponse.status === 'success') {
                self.setState({
                    users   : oResponse.data,
                    admin   : true,
                    loading : false
                });
            }
            else if(oResponse.status === 'notAdmin') {
                self.setState({
                    loading : false
                });
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    loading      : false
                });
            }
        });
    }


    /**
     * Change room.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-22
     */
    changeRoom(oEvent) {
        oEvent.preventDefault();
        log('submit', 'Settings');

        const self = this;
        const sUrl = 'user';
        const sAction = 'change-room';

        const oFormData = new FormData();
        oFormData.append('action', sAction);
        oFormData.append('room', this.state.room);

        ajax(oFormData, sUrl)
        .then(function(oResponse) {
            log(oResponse);
            if(oResponse.status === 'success') {
                Authenticator.login(oResponse.data);
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    loading      : false
                });
            }
        });
    }


    /**
     * Delete Account.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-04 
     */
    deleteAccount() {
        const bDelete = window.confirm('Willst du deinen Account wirklich löschen?');

        if(bDelete === false) {
            log('dont delete', 'Settings');
            return;
        }        

        log('delete account', 'Settings');

        const self = this;
        const sUrl = 'user';
        const sAction = 'delete-account';

        const oFormData = new FormData();
        oFormData.append('action', sAction);

        ajax(oFormData, sUrl)
        .then(function(oResponse) {
            log(oResponse);
            if(oResponse.status === 'success') {
                // NTH success message?
                Authenticator.logout();
                self.props.history.push('/');
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    loading      : false
                });
            }
        });
    }


    /**
     * Logout.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-04
     */
    logout() {
        log('logout', 'Settings');

        const self = this;
        const sUrl = 'user';
        const sAction = 'logout';

        const oFormData = new FormData();
        oFormData.append('action', sAction);

        ajax(oFormData, sUrl)
        .then(function(oResponse) {
            log(oResponse);
            if(oResponse.status === 'success') {
                Authenticator.logout();
                self.props.history.push('/');
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    loading      : false
                });
            }
        });
    }


    /**
     * Display people that want to join the room
     * or are in the room.
     * 
     * NTH in zwei Listen trennen
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-26
     * @updated 2020-02-12
     * 
     * @return {JSX}
     */
    displayRoomUsers() {
        if(this.state.users.length > 0) {
            const aUsers = [];

            aUsers.push(<div key="settings-users-heading"> Diese Nutzer sind in deinem Raum oder möchten beitreten: </div>);

            this.state.users.map(oUser => {
                aUsers.push(<RoomUsers key={oUser.id} email={oUser.email} approved={oUser.room_approved} />);
                return true;
            });

            return aUsers;
        }

        return 'Hier wird dir angezeigt wenn jemand deinem Raum beitreten möchte.';
    }


    /**
     * Leave room.
     * TODO Test
     * 
     * @author Lennart Bergmann
     * @created 2020-04-04
     * 
     * @returns {undefined}
     */
    leaveRoom() {
        log('leave room', 'Settings');

        const self = this;
        const sUrl = 'user';
        const sAction = 'leave-room';

        const oFormData = new FormData();
        oFormData.append('action', sAction);

        ajax(oFormData, sUrl)
        .then(function(oResponse) {
            log(oResponse, 'Settings');
            if(oResponse.status === 'success') {
                window.location.reload();
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    loading      : false
                });
            }
        });
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-22
     * 
     * @return {JSX}
     */
    render() {
        return (
            <Page
                class = 'settings'
                error = {this.state.error} 
                loading = {this.state.loading}
                headline = 'Einstellungen'
                errorMessage = {this.state.errorMessage}
                currentMenuItem = '/einstellungen'
                footer = {true}
            >
                <div className="settings-container">
                    Aktueller Raum: <strong>{Authenticator.getRoomId()}</strong>
                    <span onClick={oEvent => this.setState({changeRoom: !this.state.changeRoom})} className="edit icon has-text-black">
                        <i className="fas fa-pen"></i>
                    </span>

                    <div className={'field is-grouped is-grouped-multiline change-room-container' + (this.state.changeRoom ? '' :  ' hidden')}>
                        <div className="control">
                            <input 
                                id="room" 
                                className="input"
                                name="room" 
                                type="text" 
                                value={this.state.room} 
                                onChange={oEvent => this.setState({room: oEvent.target.value})} 
                                placeholder="Raum"
                            />
                        </div>

                        <div className="control">
                            <input className="button is-link" type="submit" value="Raum ändern" onClick={this.changeRoom} />                            
                        </div>
                    </div>

                    <div className="field">
                        <div className={'control' + (this.state.changeRoom ? '' :  ' hidden')}>
                            <button className="button" onClick={this.leaveRoom}>Raum verlassen</button>
                        </div>
                    </div>

                    {this.state.admin === true ? 
                        <div className="admin-container">
                            <div className="is-admin">Du bist der Besitzer dieses Raumes.</div>                            
                            <hr />
                            {this.displayRoomUsers()}
                            <hr/>
                        </div>                    
                        : <hr/>
                    }

                    <div className="field is-grouped is-grouped-multiline">
                        <div className="control">
                            <button className="button is-text" onClick={this.deleteAccount}>Account löschen</button>
                        </div>

                        <div className="control">
                            <button className="button is-danger" onClick={this.logout}>Abmelden</button>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

export default Settings;
