import React from 'react';

import {ajax} from '../util/Ajax';
import {log} from '../util/Log';

import UnitSelect from './UnitSelect';
import AutoComplete from './AutoComplete';
import OptionalItemFormInputs from './OptionalItemFormInputs';

/**
 * NTH autofill
 * NTH scroll die topbar aus dem view
 */
class ItemForm extends React.Component {  


    /**
     * Constructor.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * 
     * @created 2019-09-06
     * @updated 2020-07-13
     * 
     * @param {object} props
     * 
     * @return {undefined}
     */
    constructor(props) {
        super(props);

        this.state = {
            id           : '',
            product      : this.props.product ? this.props.product : '',
            amount       : this.props.amount ? this.props.amount : '',
            unit         : this.props.unit ? this.props.unit : 'g',
            category     : this.props.category ? this.props.category : '',
            aggregate    : this.props.aggregate ? this.props.aggregate : '',
            special      : this.props.special ? this.props.special : '',
            type         : this.props.type ? this.props.type : '',
            note         : this.props.note ? this.props.note : '',
            importance   : this.props.importance ? this.props.importance : '',
            add_to       : '',
            loading      : true,
            error        : false,
            errorMessage : '',
            submitted    : false
        };

        this.baseState = this.state;
        
        this.state.loading = false;
        this.state.add_to  = this.props.list === 'einkaufsliste' ? 'shopping-list' : 'pantry';        

        this.submit = this.submit.bind(this);
        this.reset  = this.reset.bind(this);
        this.cancel = this.cancel.bind(this);

        // this.container = null;
    }


    /**
     * Component did mount.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-18
     * 
     * @return {undefined}
     */
    // componentDidMount = () => {
    //     // die höhe an das item übergeben
    //     // das item muss die eigene höhe speichern
    //     // dann diese setzen, dann eigene setzen, animation abwarten auf auto setzen
    //     console.log('did mount');
    //     this.container = document.getElementById(this.props.id);
    //     console.log(this.container);
    //     console.log(this.container.clientHeight);
    //     // this.container.style.height = this.container.clientHeight + 'px'; 
    //     this.props.setHeight(this.container.clientHeight);
    // }


    /**
     * Validate inputs
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-04
     * @updated 2020-06-27
     * 
     * @return {bool}
     */
    validateInputs() {
        let bValid = true;

        if(this.state.product.trim() === '') {
            this.setState({
                productError : true,
                productErrMessage : 'Das Produkt darf nicht leer sein.'
            });

            bValid = false;
        }

        if(this.state.product.length > 100) {
            this.setState({
                productError : true,
                productErrMessage : 'Das Produkt darf nicht mehr als 100 Zeichen haben.'
            });

            bValid = false;
        }

        if(this.state.amount.length > 100) {
            this.setState({
                amountError : true,
                amountErrorMessage : 'Mengen dürfen maximal 100 Zeichen lang sein.'
            });

            bValid = false;
        }

        if(true === isNaN(this.state.amount)) {
            this.setState({
                amountError : true,
                amountErrorMessage : 'Menge muss eine Zahl sein.'
            });

            bValid = false;
        }

        if(this.state.category.length > 100) {
            this.setState({
                categoryError : true,
                categoryErrMessage : 'Die Kategorie darf nicht mehr als 100 Zeichen haben.'
            });

            bValid = false;
        }

        if(this.state.type.length > 100) {
            this.setState({
                typeError : true,
                typeErrMessage : 'Der Typ darf nicht mehr als 100 Zeichen haben.'
            });

            bValid = false;
        }

        if(this.state.aggregate.length > 100) {
            this.setState({
                aggregateError : true,
                aggregateErrMessage : 'Das Aggregat darf nicht mehr als 100 Zeichen haben.'
            });

            bValid = false;
        }

        if(this.state.special.length > 100) {
            this.setState({
                specialError : true,
                specialErrMessage : 'Spezial darf nicht mehr als 100 Zeichen haben.'
            });

            bValid = false;
        }

        if(this.state.note.length > 1000) {
            this.setState({
                noteError : true,
                noteErrMessage : 'Die Notiz darf nicht mehr als 1000 Zeichen haben.'
            });

            bValid = false;
        }

        return bValid;
    }
   

    /**
     * Submit.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * 
     * @created 2019-09-06
     * @updated 2020-09-11
     * 
     * @param {object} oEvent
     * 
     * @return {undefined}
     */
    submit(oEvent = null) {
        if(null !== oEvent) {
            oEvent.preventDefault();
        }
        const self = this;
        // log(this.state, 'ItemForm');

        // Wait for blur to fire
        setTimeout(() => {
            if(false === this.validateInputs()) {
                return false;
            }       
    
            const oFormData = new FormData();
            if(this.props.action === 'edit') {
                oFormData.append('action', 'edit-item');
                oFormData.append('id', this.props.id);
            }
            else {
                oFormData.append('action', 'add-item');
            }
    
            oFormData.append('product', this.state.product);
            oFormData.append('amount', this.state.amount);
            oFormData.append('unit', this.state.unit);
            oFormData.append('category', this.state.category);
            oFormData.append('type', this.state.type);
            oFormData.append('aggregate', this.state.aggregate);
            oFormData.append('special', this.state.special);
            oFormData.append('importance', this.state.importance);
            oFormData.append('note', this.state.note);
            oFormData.append('add_to', this.state.add_to);
    
            ajax(oFormData, 'items')
            .then(function(oResponse) {
                if(oResponse.status === 'success') {
                    // self.props.history.push('/' + self.state.add_to);
    
                    log(oResponse, 'ItemForm');
                    self.setState({
                        submitted : true
                    });
    
                    // Callback
                    if(self.props.submitCallback) {
                        self.props.submitCallback({
                            product    : self.state.product, 
                            amount     : self.state.amount, 
                            unit       : self.state.unit, 
                            category   : self.state.category, 
                            type       : self.state.type, 
                            aggregate  : self.state.aggregate, 
                            special    : self.state.special, 
                            importance : self.state.importance, 
                            note       : self.state.note, 
                            add_to     : self.state.add_to,
                            id         : oResponse.data.id
                        });
                    } 
    
                    // Hide again
                    self.props.hideForm();
                }
                else {
                    self.setState({
                        error        : true,
                        errorMessage : oResponse.message,
                        loading      : false
                    });
                }
                // log(oResponse.status, 'ItemForm');
            });
        }, 110);
    }


    /**
     * Display unit dropdown.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-28
     * 
     * @return {JSX}
     */
    displayUnitDropdown() {
        return (
            <div className="control">
                <label className="label" htmlFor="unit">Einheit</label>
                <div className="select">
                    <UnitSelect 
                        id="unit"
                        value={this.state.unit}
                        onChange={oEvent => this.setState({unit: oEvent.target.value})}
                    />
                </div>
            </div>
        );
    }


    /**
     * Display list radios.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-28
     * 
     * @return {JSX}
     */
    displayListRadios() {
        return (
            <div className="field">
                <div className="control">
                    <label className="radio" htmlFor="pantry">
                        <input 
                            type="radio" 
                            id="pantry"
                            name="add_to" 
                            value="pantry" 
                            checked={this.state.add_to === 'pantry'} 
                            onChange={oEvent => this.setState({add_to: 'pantry'})} 
                        />                        
                         Vorrat
                    </label>
                    
                    <label className="radio" htmlFor="shopping-list">
                        <input 
                            type="radio" 
                            id="shopping-list" 
                            name="add_to" 
                            value="shopping-list" 
                            checked={this.state.add_to === 'shopping-list'} 
                            onChange={oEvent => this.setState({add_to: 'shopping-list'})} 
                        />
                         Einkaufsliste
                    </label>
                </div>
            </div>
        );
    }


    /**
     * Reset to initial state.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-28
     * 
     * @return {undefined}
     */
    reset() {
        this.setState(this.baseState);
    }


    /**
     * Go back.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-10
     * 
     * @param {object} oEvent
     * 
     * @return {undefined}
     */
    cancel(oEvent) {
        oEvent.preventDefault();
        this.props.hideForm();
        // window.history.back();
    }


    /**
     * Update state. 
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * 
     * @created 2020-05-01
     * @updated 2020-06-07
     * 
     * @param {object} oItem
     * 
     * @return {undefined}
     */
    setItem = oItem => {
        this.setState({
            product   : oItem.product,
            unit      : oItem.unit ? oItem.unit : this.state.unit,
            category  : oItem.category ? oItem.category : this.state.category,
            aggregate : oItem.aggregate ? oItem.aggregate : this.state.aggregate,
            type      : oItem.type ? oItem.type : this.state.type,
            special   : oItem.special ? oItem.special : this.state.special
        });
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * 
     * @created 2019-09-06
     * @updated 2020-09-11
     * 
     * @return {JSX}
     */
    render() {
        return (
            <div id={this.props.id} className={"item-form"}>
                <form className="form" autoComplete="off" onSubmit={this.submit}>

                    <div className="product-container field">
                        <label className="label" htmlFor="product">Produkt</label>
                        <div className="control">
                            <AutoComplete setItem={this.setItem} product={this.state.product} submit={this.submit} />
                        </div>

                        {this.state.productError === true ? this.state.productErrMessage : ''}
                    </div>                            

                    <div className="field is-grouped amount-unit-container">
                        <div className="control is-expanded amount-container">
                            <label className="label" htmlFor="amount">Menge</label>
                            <input className="input" type="number" id="amount" name="amount" step="0.01" 
                                value={this.state.amount} onChange={oEvent => this.setState({amount: oEvent.target.value})} maxLength="100" />

                            {this.state.amountError === true ? this.state.amountErrorMessage : ''}
                        </div>

                        {this.displayUnitDropdown()}
                    </div>

                    {this.displayListRadios()}                    
                    
                    <OptionalItemFormInputs
                        setState   = {(oState) => this.setState(oState)}
                        category   = {this.state.category}
                        aggregate  = {this.state.aggregate}
                        type       = {this.state.type}
                        special    = {this.state.special}
                        note       = {this.state.note}
                        importance = {this.state.importance}
                        show       = {true}
                    />

                    <div className="field is-grouped is-grouped-right">
                        <div className="control">
                            <button onClick={this.cancel} className="button btn-cancel">Abbrechen</button>
                        </div>
                        <div className="control">
                            <input className="button is-link" type="submit" value={this.props.buttonLabel ? this.props.buttonLabel : "Hinzufügen"} />
                        </div>
                    </div>

                </form> 
            </div>
        );
    }
}

export default ItemForm;
