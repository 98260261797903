import React from 'react';

const Error = props => {    

    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-28
     * @updated 2020-04-19
     * 
     * @return {JSX}
     */
    return (
        <div className="page error">
            <h2>Fehler</h2>
            {props.errorMessage}
        </div>
    );
}

export default Error;