import React from 'react';
import {Link} from 'react-router-dom';

const RecipeMenu = props => {    

    /**
     * Get Link for secondary menu.
     * NTH mark active.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-12-01
     * 
     * @returns {JSX}
     */
    const getLink = (sPath, sName, sClassName = '') => {
        const oTo = {
            pathname : sPath,
        };

        return (
            <Link to={oTo} className={sClassName}>{sName}</Link>
        );
    };


    /**
     * Render.
     * 
     * @author Lennart Bergmann
     * @created 2020-04-01
     * 
     * @returns {JSX}
     */
    return (
        <div className="secondary-menu">
            <div className="inner">
                {getLink('/rezepte', 'Alle', 'button smart-recipes-link')}
                {getLink('/favoriten', 'Favoriten', 'button')}
                {getLink('/meine-rezepte', 'Meine Rezepte', 'button')}
                {getLink('/smart-recipes', 'Smart Recipes', 'button is-success smart-recipes-link')}
            </div>
        </div>
    );
}

export default RecipeMenu;