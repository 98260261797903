import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Authenticator from '../util/Authenticator';

const PrivateRoute = ({component: Component, ...rest}) => {
    return (

        <Route {...rest} 
            render={props => (
                Authenticator.isAuthenticated() ?
                    <Component {...props} {...rest} />
                : <Redirect to="/" />
        )} />
    );
};

export default PrivateRoute;