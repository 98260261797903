import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import logo from '../../assets/images/logo.png';
import Error from '../partial/Error';
import Loading from '../partial/Loading';
import Authenticator from '../util/Authenticator';

import Menu from '../partial/Menu';
import Footer from '../partial/Footer';

class Page extends React.Component {


    /**
     * Display Page.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-08
     * 
     * @return {JSX}
     */
    displayPage() {
        if(this.props.error === true) {
            return <Error errorMessage={this.props.errorMessage} />;
        }
        else if(this.props.loading === true) {
            return <Loading />;
        }
        else {
            return this.props.children;
        }
    }


    /**
     * Display recipe meta data.
     * 
     * @author Lennart Bergmann
     * @created 2020-07-13
     * 
     * @returns {JSX}
     */
    displayRecipeMetaData = () => {
        return (
            <script type="application/ld+json">
            {`
                {
                    "@context": "http://schema.org",
                    "@type": "Recipe",
                    "datePublished": "${this.props.recipe.published}",
                    "image": "${this.props.recipe.image}",
                    "recipeIngredient": ${this.props.recipe.ingredients},
                    "name": "${this.props.recipe.title}",
                    "recipeInstructions": "${this.props.recipe.directions}",
                    "recipeYield": "${this.props.recipe.serves} Portionen" 
                }
            `}
            </script>
        );
    }
    

    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-08
     * @updated 2020-09-09
     * 
     * @return {JSX}
     */
    render() {

        return (
            <div className={'page' + (true === this.props.footer ? ' with-footer' : '' ) + (true === this.props.full_width ? ' full-width' : '')}>
                <Helmet>
                    <title>{(this.props.title ? this.props.title : this.props.headline) + " | kalusto.de"} </title>
                    {this.props.recipe ? this.displayRecipeMetaData() : ''}
                </Helmet>

                {/* Title */}
                <div className="title-container">
                    <div className="inner">
                        {/* {this.props.authenticated ?
                            ''
                            : <div>Back</div>
                        } */}
                        <Link to="/">
                            <img className="logo" src={logo} alt="Logo" />
                        </Link>
                        <h1 className="title is-4">
                            {this.props.headline}
                        </h1>
                        {Authenticator.isAuthenticated() ? 
                            ''
                            : <Link to="/" className="login">
                                Anmelden/Registrieren
                            </Link> 
                        }
                    </div>
                </div>

                {this.props.toggleAlphabetical && Authenticator.isAuthenticated() ? 
                    <div className="toggle-alphabetical-container">                        
                        <button className="toggle-alphabetical-items" onClick={this.props.toggleAlphabetical}>
                            {this.props.alphabetical ? <i className="fas fa-archive"></i> :<i className="fas fa-sort-alpha-up"></i>}                            
                        </button> 
                    </div>
                    : ''}

                {/* Content */}
                <div className={'content-container ' + this.props.class}>
                    {this.displayPage()}
                </div>

                {/* Menu */}
                {this.props.hideMenu === true || this.props.error === true ? 
                    '' 
                    : <Menu 
                        current      = {this.props.currentMenuItem} 
                        posMenuFixed = {this.props.posMenuFixed !== undefined ? this.props.posMenuFixed : true} 
                      />
                }

                {/* Footer */}
                {true === this.props.footer ? <Footer /> : '' }

            </div>
        );

        
    }
}

export default Page;