import React from 'react';
import { getCategories } from '../util/Categories';

class OptionalItemFormInputs extends React.Component 
{
    /**
     * Constructor.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-01
     * @updated 2020-06-28
     * 
     * @returns {undefined}
     */
    constructor(props) {
        super(props);

        this.state = {
            show : this.props.show
        };
    }


    /**
     * Toggle inputs.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-01
     * @updated 2020-06-28
     * 
     * @param {object} oEvent
     * 
     * @returns {undefined}
     */
    toggle = () => {
        this.setState({
            show : !this.state.show
        });
    }


    /**
     * Display inputs.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-01
     * @updated 2020-06-27
     * 
     * @returns {JSX}
     */
    displayInputs = () => {
        return (
            <React.Fragment>
                <div className="field">
                    <label htmlFor="category" className="label">Kategorie</label>
                    <div className="select">
                        <select id="category" value={this.props.category ? this.props.category : ''} onChange={oEvent => this.props.setState({category : oEvent.target.value})}>
                            <option value="">Bitte wählen</option>
                            {this.getOptions()}
                        </select>
                    </div>
                </div> 
                {/* <div className="field">
                    <label className="label" htmlFor="type">Typ</label>
                    <div className="control">
                        <input 
                            className="input" 
                            id="type" 
                            type="text"
                            name="type" 
                            value={this.props.type ? this.props.type : ''} 
                            onChange={oEvent => this.props.setState({type : oEvent.target.value})}                            
                        />
                    </div>
                </div> */}
                <div className="field">
                    <label className="label" htmlFor="aggregate">Art</label>
                    <div className="control">
                        <input 
                            className="input" 
                            id="aggregate" 
                            type="text"
                            name="aggregate" 
                            value={'null' !== this.props.aggregate ? this.props.aggregate : ''} 
                            onChange={oEvent => this.props.setState({aggregate : oEvent.target.value})}                            
                        />
                    </div>
                    <p className="help">z.B. frisch, gefroren, getrocknet, passiert</p>
                </div>
                <div className="field">
                    <label htmlFor="importance" className="label">Wichtigkeit</label>
                    <div className="select">
                        <select id="importance" value={this.props.importance ? this.props.importance : 'normal'} onChange={oEvent => this.props.setState({importance : oEvent.target.value})}>
                            <option value="high">Hoch</option>
                            <option value="normal">Normal</option>
                            <option value="low">Niedrig</option>
                        </select>
                    </div>
                </div> 
                <div className="field">
                    <label className="label" htmlFor="type">Notiz</label>
                    <div className="control">
                        <input 
                            className="input" 
                            id="note" 
                            type="text"
                            name="note" 
                            value={this.props.note ? this.props.note : ''} 
                            onChange={oEvent => this.props.setState({note : oEvent.target.value})}                            
                        />
                    </div>
                </div>
                {/* <div className="field">
                    <label className="label" htmlFor="special">Spezial</label>
                    <div className="control">
                        <input 
                            className="input" 
                            id="special" 
                            type="text"
                            name="special" 
                            value={'null' !== this.props.special ? this.props.special : ''}
                            onChange={oEvent => this.props.setState({special : oEvent.target.value})}                            
                        />
                    </div>
                </div> */}
            </React.Fragment>
        );
    }


    /**
     * Get options.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-18
     * 
     * @returns {array}
     */
    getOptions = () => {
        const oCategories = getCategories();
        const aOptions = [];

        let iKey = 0;
        for(iKey in oCategories) {
            aOptions.push(<option key={iKey} value={iKey}>{oCategories[iKey]}</option>);
        }

        return aOptions;
    }
    

    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-05-15
     * @updated 2020-06-28
     * 
     * @return {JSX}
     */
    render = () => {
        return (
            <div className="optional-itemform-inputs-container">
                <h3 className="headline" onClick={this.toggle}>
                    <span className="optional">Erweiterte Einstellungen</span>
                    <span className="toggle"><i className={"fas fa-angle-" + (this.state.show ? "down" : "left")}></i></span>
                </h3>
                {this.state.show ? this.displayInputs() : ''}
            </div>
        );
    }
}

export default OptionalItemFormInputs;