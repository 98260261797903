/**
 * Get categories.
 * 
 * @author Lennart Bergmann <mail@lennartbergmann.com>
 * @created 2020-06-18
 * 
 * @return {object}
 */
function getCategories() {
    return {
        100 : 'Obst & Gemüse',
        200 : 'Gewürze, Öl & Essig',
        250 : 'Nüsse & Getreide',
        300 : 'Back- & Teigwaren',
        400 : 'Milchprodukte & Eier',
        450 : 'Fisch & Fleisch',
        475 : 'Getränke',
        500 : 'Süßigkeiten & Snacks',
        600 : 'Körperpflege & Hygiene',
        100000 : 'Sonstiges'
    }
}


/**
 * Get given categories name.
 * 
 * @author Lennart Bergmann <mail@lennartbergmann.com>
 * @created 2020-06-27
 * 
 * @param {int} iCategory
 * 
 * @return {object}
 */
function getCategoryName(iCategory) {
    return getCategories()[iCategory];
}

export { getCategories, getCategoryName };