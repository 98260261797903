import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


// Components
import { log } from './components/util/Log';
import { ajax } from './components/util/Ajax';
import PrivateRoute from './components/util/PrivateRoute';
import Authenticator from './components/util/Authenticator';

import NotFound from './components/pages/404';
import Start from './components/pages/Start';
import Settings from './components/pages/Settings';
import ItemList from './components/pages/ItemList';
import Recipes from './components/pages/Recipes';
import SingleRecipe from './components/pages/SingleRecipe';
import RecipeForm from './components/pages/RecipeForm';

/**
 * NTH Admin Bereich zb um Rezepte zu approven
 * NTH Login-Overlay für nicht eingeloggte bei Klick auf Link statt Leitung auf /
 * TODO Amazon
 * TODO PWA
 */
class App extends React.Component {

    /**
     * Constructor
     * 
     * @author Lennart Bergmann
     * @created 2019-09-22
     * 
     * @param {object} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isAuthenticated: false,
            room_id: '',
            user_id: ''
        };
    }


    /**
     * Component did mount.
     * 
     * @author Lennart Bergmann
     * @created 2021-01-07
     */
    componentDidMount() {
        this.isAuthenticated();
    }


    /**
     * Authenticate user if possible.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-22
     */
    isAuthenticated() {

        const self = this;
        const sUrl = 'user';
        const sAction = 'authenticate';

        const oFormData = new FormData();
        oFormData.append('action', sAction);

        return ajax(oFormData, sUrl)
            .then(function (oResponse) {
                log(oResponse, 'App');

                if (oResponse.status === 'success') {
                    Authenticator.login(oResponse.data);
                    self.setState({
                        loading: false,
                    });
                }
                // Force Reload
                else if (oResponse.status === 'refresh') {
                    window.location.reload();
                }
                else {
                    // Do nothing if status = error

                    self.setState({
                        loading: false,
                    });
                }

            });
    }


    /**
     * Display pages based on route.
     * 
     * @author Lennart Bergmann
     * @updated 2021-01-09
     */
    display() {
        if (this.state.loading) {
            return '';
        }
        else {
            const StartPage = (props) => {
                return (
                    <Start
                        {...props}
                    />
                );
            }

            const RecipesPage = (props) => {
                return (
                    <Recipes
                        get="all"
                        {...props}
                    />
                );
            }

            const FavoriteRecipesPage = (props) => {
                return (
                    <Recipes
                        get="favorites"
                        {...props}
                    />
                );
            }

            const MyRecipesPage = (props) => {
                return (
                    <Recipes
                        get="my"
                        {...props}
                    />
                );
            }

            const SmartRecipesPage = (props) => {
                return (
                    <Recipes
                        get="smart"
                        {...props}
                    />
                );
            }

            const SingleRecipePage = (props) => {
                return (
                    <SingleRecipe
                        {...props}
                    />
                );
            }

            return (
                <React.Fragment>
                    <Switch>
                        <Route path="/" exact render={StartPage} />
                        <Route path="/rezepte/" exact render={RecipesPage} />
                        <Route path="/favoriten/" exact render={FavoriteRecipesPage} />
                        <Route path="/meine-rezepte/" exact render={MyRecipesPage} />
                        <Route path="/smart-recipes/" exact render={SmartRecipesPage} />
                        <Route path="/rezept/:title" exact render={SingleRecipePage} />

                        <PrivateRoute path="/einstellungen" exact component={Settings} />
                        <PrivateRoute path="/vorrat" exact component={ItemList} />
                        <PrivateRoute path="/einkaufsliste" exact component={ItemList} />
                        <PrivateRoute path="/neues-rezept" exact action="add" component={RecipeForm} />
                        <PrivateRoute path="/rezept-bearbeiten/:url" exact action="edit" component={RecipeForm} />

                        <Route component={NotFound} />
                    </Switch>
                </React.Fragment>
            )
        }
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-06
     * 
     * @return {JSX}
     */
    render() {
        return (
            <Router>
                <div className="app">
                    {this.display()}
                </div>
            </Router>
        );
    }
}

export default App;