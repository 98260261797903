import React from 'react';
import {Link} from 'react-router-dom';

const AddRecipe = props => {    

    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-09
     * @updated 2020-04-19
     * 
     * @return {JSX}
     */
    return (
        <div className="add-button-container">
            <Link to={props.to} className="add-button" >
                <span className="icon is-large has-text-black">
                    <i className="fas fa-3x fa-plus-circle"></i>
                </span>
            </Link>
        </div>
    );
}

export default AddRecipe;
