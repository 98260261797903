import React from 'react';

import {ajax} from '../util/Ajax';
import { getCategoryName } from '../util/Categories';

class AutoComplete extends React.Component {

    /**
     * Constructor
     * 
     * @author Lennart Bergmann
     * @updated 2021-01-09
     * 
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            product: this.props.product ? this.props.product : '',
            matches: [],
            displayMatches : true,
            autoFocus : (false === this.props.autoFocus) || (this.props.product) ? false : true
        };

        this.getMatchesTimeout = null;
        this.getMatchesId = null;
        this.settingMatch = false;
    }


    /**
     * Updates the state and matches.
     * 
     * @author Lennart Bergmann
     * 
     * @created 2020-04-26
     * @updated 2020-06-07
     * 
     * @param {string} sInput
     * 
     * @return {undefined}
     */
    updateItem = sInput => {
        this.setState({
            product : sInput
        });

        if(1 < sInput.length) {
            /* Add the input text as first match */
            const oInputMatch = {
                id : 'input',
                product: sInput,
                category : null,
                aggregate : null,
                special : null,
                type : null,
                unit : 'g'
            };
            const aMatches = [];
            aMatches.push(oInputMatch);

            this.setState({
                matches        : aMatches,
                loadingMatches : true,
                displayMatches : true
            }, () => {
                clearTimeout(this.getMatchesTimeout);
                this.getMatchesTimeout = setTimeout(() => {this.getMatches(sInput)}, 300);
            }); 
        }
        else {
            this.setState({
                matches : []
            });
        }
    }


    /**
     * Looks through the item array for matches.
     * 
     * @author Lennart Bergmann
     * 
     * @created 2020-05-01
     * @updated 2020-06-07
     * 
     * @param {string} sInput
     * 
     * @return {undefined}
     */
    getMatches = sInput => {
        const iTimeNow = Date.now();
        this.getMatchesId = iTimeNow;

        const oFormData = new FormData();
        oFormData.append('action', 'getMatches');
        oFormData.append('input', sInput);

        ajax( oFormData, 'items')
        .then(oResponse => {
            if(oResponse.status === 'success') {
                if(iTimeNow === this.getMatchesId) {
                    const aStateMatches = this.state.matches;

                    this.setState({
                        matches        : aStateMatches.concat(oResponse.data),
                        loadingMatches : false
                    });
                }
            }
        });
    }


    /**
     * Display all matches.
     * 
     * @author Lennart Bergmann
     * 
     * @created 2020-04-27
     * @updated 2020-06-07
     * 
     * @return {JSX}
     */
    displayMatches = () => {
        if(true === this.state.displayMatches) {
            return(
                <div className="match-container">
                    {this.getMatchesHTML()}
                    {this.state.loadingMatches ? <div className="match loading">Loading ...</div> : ''}
                </div>
            );
        }
        return '';
    }


    /**
     * Builds the HTML for all matches.
     * 
     * @author Lennart Bergmann
     * 
     * @created 2020-04-27
     * @updated 2020-06-07
     * 
     * @return {JSX}
     */
    getMatchesHTML = () => {
        return this.state.matches.map(oMatch => {
            return(
                <div key={oMatch.id} className="match" onClick={this.setMatch.bind(this, oMatch)}>
                    <span className="product">{oMatch.product}</span>
                    {this.displayAggregate(oMatch.aggregate)}
                    {this.displayCategory(oMatch.category)}
                </div>
            );
        });
    }


    /**
     * Display category name.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-27
     * 
     * @param {string}
     * 
     * @returns {JSX}
     */
    displayCategory = sCategory => {
        if(sCategory) {
            return <div className="category">{getCategoryName(sCategory)}</div>
        }
        return '';
    }

    /**
     * Display the aggregate if specified.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-25
     * 
     * @param {string}
     * 
     * @returns {JSX}
     */
    displayAggregate = sAggregate => {
        if('null' !== sAggregate) {
            return <div>{sAggregate}</div>
        }
        return '';
    }


    /**
     * Sets match, propagates to parent.
     * 
     * @author Lennart Bergmann
     * 
     * @created 2020-05-01
     * @updated 2020-06-07
     * 
     * @param {object} oMatch
     * @param {object} oEvent
     * 
     * @return {undefined}
     */
    setMatch = (oMatch, oEvent) => {
        this.settingMatch = true;
        this.setState({
            product : oMatch.product,
            matches : []
        });

        this.props.setItem(oMatch);
    }


    /**
     * Display matches on focus.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-06
     * @updated 2020-06-07
     * 
     * @param {string} sInput
     * 
     * @returns {undefined}
     */
    onFocusHandler = sInput => {
        if(1 < sInput.length) {
            this.setState({
                displayMatches : true
            });
        }
    }


    /**
     * Hide matches on blur.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-06
     * @updated 2020-06-07
     * 
     * @returns {undefined}
     */
    onBlurHandler = sInput => {
        setTimeout(() => {
            if(false === this.settingMatch) {
                this.props.setItem({
                    id : 'input',
                    product: sInput
                });
            }
            this.settingMatch = false;
            this.setState({displayMatches : false})
        }, 100);
    }


    /**
     * Submits if the user hits enter.
     * 
     * @author Lennart Bergmann
     * @created 2020-09-11
     * 
     * @param {object} oEvent 
     */
    onKeyPressHandler = oEvent => {
        if('Enter' === oEvent.key) {
            oEvent.preventDefault();
            this.onBlurHandler(oEvent.target.value);
            this.props.submit();
        }
    }


    /**
     * Render
     * 
     * @author Lennart Bergmann
     * @created 2020-04-25
     * @created 2020-09-11
     * 
     * @return {JSX}
     */
    render() {
        return(
            <div className="autocomplete-container">
                <input 
                    className="input" 
                    id="product" 
                    type="text" 
                    name="product" 
                    value={this.state.product} 
                    onChange={oEvent => this.updateItem(oEvent.target.value)} 
                    onFocus={oEvent => this.onFocusHandler(oEvent.target.value)}
                    onBlur={oEvent => this.onBlurHandler(oEvent.target.value)}
                    onKeyPress={oEvent => this.onKeyPressHandler(oEvent)}
                    maxLength="100" 
                    autoFocus={this.state.autoFocus}  
                    required 
                />
                {0 < this.state.matches.length ? this.displayMatches() : '' }
            </div>
        );
    }
}

export default AutoComplete;