import React from 'react';

import {getUnit} from '../util/Unit';
// import {log} from '../util/Log';
import {ajax} from '../util/Ajax';

import ItemForm from './ItemForm';

class Item extends React.Component {


    /**
     * Constructor.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-07
     * @updated 2020-06-27
     * 
     * @returns {undefined}
     */
    constructor(props) {
        super(props);

        this.state = {
            moved      : false,
            options    : false,
            moving     : false,
            product    : this.props.item.product,
            amount     : this.props.item.amount,
            unit       : this.props.item.unit,
            category   : this.props.item.category,
            note       : this.props.item.note,
            aggregate  : this.props.item.aggregate,
            importance : this.props.item.importance,
            status     : this.props.status
        };

        this.dragElement   = this.dragElement.bind(this);
        this.moveItem      = this.moveItem.bind(this);
        this.removeItem    = this.removeItem.bind(this);
        this.changeOptions = this.changeOptions.bind(this);
        this.hideOptions   = this.hideOptions.bind(this);
        this.displayInfo   = this.displayInfo.bind(this);
        this.updateItem    = this.updateItem.bind(this);

        this.moved = false;
        this.movedVertical = false;
    }


    /**
     * Make this item draggable.
     * Call move/remove if threshold was passed.
     * 
     * @author Lennart Bergmann 
     * @created 2019-09-07
     * @updated 2020-12-23
     * 
     * @param {object} oEvent
     */
    dragElement(oEvent) {
        if(this.state.options === true) {
            return;
        }

        const oElement = oEvent.target;
        let iMouseX = oEvent.clientX;
        // let iMouseY = oEvent.clientY;
        let iMouseYOrig = oEvent.clientY;

        /* Remove transition to make item more reactive. */
        oElement.style.transition = null;

        document.ontouchend = document.onmouseup = () => {
            document.onmouseup = null;
            document.onmousemove = null;
            document.ontouchmove = null;
            document.ontouchend = null;

            /* Check if an item was moved. */
            if(true !== this.moved) {
                return;
            }

            /* Reset moved. */
            this.movedVertical = false;

            /* Activate transition. */
            oElement.style.transition = 'all .3s';

            /* Item was moved to the right. */
            if(oElement.offsetLeft > oElement.parentNode.offsetWidth * 0.4) {
                oElement.style.left = '104%';

                /* Show loading icon. */
                this.setState({
                    moving : true
                });

                if(this.props.status === 'vorrat') {
                    this.moveItem('shopping-list', oElement);
                }
                else {
                    this.removeItem(oElement);
                }

            }
            /* Item was moved to the left. */
            else if(oElement.offsetLeft * (-1) > oElement.parentNode.offsetWidth * 0.4) {
                oElement.style.left = '-104%';

                /* Show loading icon. */
                this.setState({
                    moving : true
                });

                if(this.props.status === 'einkaufsliste') {
                    this.moveItem('pantry', oElement);
                }
                else {
                    this.removeItem(oElement);
                }

            }
            /* Reset item, as it wasn't moved far enough. */
            else {
                oElement.style.left = 0;
            }
        };

        document.onmousemove = oMouseMoveEvent => {            
            oMouseMoveEvent.preventDefault();
            iMouseX = this.drag(iMouseX, iMouseYOrig, oMouseMoveEvent.clientX, oMouseMoveEvent.clientY, oElement);
        };

        document.ontouchmove = oTouchMoveEvent => {
            iMouseX = this.drag(iMouseX, iMouseYOrig, oTouchMoveEvent.touches[0].clientX, oTouchMoveEvent.touches[0].clientY, oElement);
        }
    }


    /**
     * Drag item.
     * 
     * @author Lennart Bergmann
     * @created 2020-12-23
     * 
     * @param {int} iMouseX 
     * @param {int} iMouseY 
     * @param {int} iClientX 
     * @param {int} iClientY 
     * @param {object} oElement 
     * 
     * @returns {int}
     */
    drag(iMouseX, iMouseY, iClientX, iClientY, oElement) {
        this.moved = true;

        /* Don't move if scroll is vertical. */
        if(this.movedVertical) {
            return;
        }

        /* Don't move item if the scroll is vertical */
        let iMouseNowY = iMouseY - iClientY;
        let iDiffY = Math.abs(iMouseNowY);
        if(iDiffY > 30) {
            this.movedVertical = true;
            return;
        }

        /* Move the item */
        let iMouseNowX = iMouseX - iClientX;
        oElement.style.left = (oElement.offsetLeft - iMouseNowX) + 'px'; 

        return iClientX;
    }


    /**
     * Move this item to given list
     * and remove from view.
     * 
     * @author Lennart Bergmann 
     * @created 2019-09-07
     * @created 2020-09-04
     * 
     * @param {string} sMoveTo
     * @param {object} oNode
     */
    moveItem(sMoveTo, oNode) {
        const sUrl = 'items';
        const oFormData = new FormData();

        oFormData.append('action', 'move');
        oFormData.append('id', this.props.item.id);
        oFormData.append('status', sMoveTo);

        ajax( oFormData, sUrl)
        .then(oResponse => {

            this.setState({
                moving : false
            });

            if(oResponse.status === 'success') {
                oNode.style.minHeight = 0;
                oNode.style.height = '0px';
                oNode.style.marginBottom = 0;
                oNode.style.padding = 0;

                if('function' === typeof this.props.removeFromRecipe) {
                    this.props.removeFromRecipe();
                }
                
                setTimeout(() => {this.props.removeItem(this.props.item)}, 300);
            }
            else {
                this.setState({
                    moveError : true,
                    moveErrorMessage : oResponse.message
                });
            }
        });
    }


    /**
     * Remove this item from db and view.
     * 
     * @author Lennart Bergmann 
     * @created 2019-09-07
     * @updated 2020-09-04
     * 
     * @param {object} oNode
     */
    removeItem(oNode) {
        const sUrl = 'items';
        const oFormData = new FormData();

        oFormData.append('action', 'remove');
        oFormData.append('id', this.props.item.id);

        ajax( oFormData, sUrl)
        .then(oResponse => {

            this.setState({
                moving : false
            });

            if(oResponse.status === 'success') {
                oNode.style.minHeight = 0;
                oNode.style.height = '0px';
                oNode.style.marginBottom = 0;
                oNode.style.padding = 0;
                
                if('function' === typeof this.props.removeFromRecipe) {
                    this.props.removeFromRecipe();
                }

                setTimeout(() => {this.props.removeItem(this.props.item)}, 300);
            }
            else {
                this.setState({
                    removeError : true,
                    removeErrorMessage : oResponse.message
                });
            }
        });
    }

    
    /**
     * Update this item.
     * 
     * @author Lennart Bergmann 
     * @created 2019-11-15
     * 
     * @param {obecjt} oData
     */
    updateItem(oData) {
        this.setState({
            product    : oData.product,
            amount     : oData.amount,
            unit       : oData.unit,
            status     : oData.add_to,
            category   : oData.category,
            aggregate  : oData.aggregate,
            note       : oData.note,
            importance : oData.importance
        });
    }


    /**
     * Display the options.
     * 
     * @author Lennart Bergmann 
     * @created 2019-09-07
     * @updated 2020-05-21
     * 
     * @return {JSX}
     */
    displayOptions() {
        if(this.state.options === true) {
            return (
                <ItemForm 
                    hideForm       = {this.hideOptions}
                    list           = {this.state.status} 
                    buttonLabel    = 'Speichern'
                    product        = {this.state.product}
                    amount         = {this.state.amount}
                    unit           = {this.state.unit}
                    category       = {this.state.category}
                    type           = {this.state.type}
                    aggregate      = {this.state.aggregate}
                    special        = {this.state.special}
                    note           = {this.state.note}
                    importance      = {this.state.importance}
                    submitCallback = {this.updateItem}
                    action         = 'edit'
                    id             = {this.props.item.id}
                    // setHeight      = {this.setHeight}
                />
            );
        }
        else {
            return '';
        }
    }


    /**
     * Set to display/hide
     * only if the item wasn't moved before.
     * 
     * @author Lennart Bergmann 
     * @created 2019-09-07
     * @updated 2020-05-21
     * 
     * @returns {undefined}
     */
    changeOptions() {
        if(this.moved === true) {
            this.moved = false;
        }
        else {
            if(this.state.options !== true) {
                this.props.toggleEditMode();
                this.setState({
                    options: true
                });
            }
        }
    }


    /**
     * Hide edit form
     * 
     * @author Lennart Bergmann
     * @updated 2020-05-21
     * 
     * @returns {undefined}
     */
    hideOptions() {
        this.props.toggleEditMode();
        this.setState({
            options: false
        });
    }


    /**
     * Display product information
     * 
     * @author Lennart Bergmann
     * @updated 2020-06-26
     * 
     * @returns {JSX}
     */
    displayInfo() {
        if(this.state.options === true) {
            return '';
        }
        else {
            return (
                <React.Fragment>
                    <span className="product">{this.state.product}</span>
                    {this.displayAmount()}
                    {this.displayUnit()}
                    {this.displayAggregate()}
                    {this.displayNote()}
                    {this.displayImportance()}
                </React.Fragment>
            );
        }
    }


    /**
     * Display the amount if specified.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-25
     * 
     * @returns {JSX}
     */
    displayAmount = () => {
        if(this.state.amount) {
           return  <span className="amount"> - {this.state.amount}</span>;
        }
        return '';
    }


    /**
     * Display the unit if specified.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-25
     * 
     * @returns {JSX}
     */
    displayUnit = () => {
        if(this.state.amount) {
            return <span className="unit">{getUnit(this.state.unit)}</span>;
        }
        return '';
    }


    /**
     * Display the aggregate if specified.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-25
     * 
     * @returns {JSX}
     */
    displayAggregate = () => {
        if(null !== this.state.aggregate) {
            return <div className="aggregate">{this.state.aggregate}</div>
        }
        return '';
    }

    /**
     * Display the note if specified.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-26
     * 
     * @returns {JSX}
     */
    displayNote = () => {
        if(this.state.note) {
            return <div className="note">"{this.state.note}"</div>
        }
        return '';
    }


    /**
     * Display the importance if specified.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-26
     * @updated 2020-06-27
     * 
     * @returns {JSX}
     */
    displayImportance = () => {
        let sIcon = '';
        if('high' === this.state.importance) {
            sIcon = "fas fa-angle-double-up";
        }
        else if('low' === this.state.importance) {
            sIcon = "fas fa-angle-double-down";
        }
        if('' !== sIcon) {
            return <div className={"importance " + this.state.importance}><i className={sIcon}></i></div>;
        }
        return '';
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann 
     * @created 2019-09-06
     * 
     * @return {JSX}
     */
    render() {
        return (
            <div id={"item-container" + this.props.item.id} className={"item-container" + (this.state.options === true ? " options" : "")}>
                <div className={'item ' + this.state.status} onTouchStart={this.dragElement} onMouseDown={this.dragElement} onClick={this.changeOptions}>
                    <div className="inner">
                        {this.displayInfo()}                        
                        {this.displayOptions()}
                        {this.state.moveError === true ? this.state.moveErrorMessage : ''}
                        {this.state.removeError === true ? this.state.removeErrorMessage : ''}
                    </div>
                </div>
                <div className={"loading-container" + (this.state.moving === false ? ' hidden' : '')}>
                    <div className="inner">
                        <span className="fas fa-spinner fa-pulse"></span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Item;
