import React from 'react';
import {Link} from 'react-router-dom';

import {ajax} from '../util/Ajax';
import {log} from '../util/Log';

import sp_pantry from '../../assets/images/smartphone_pantry.png';
import sp_shoppinglist from '../../assets/images/smartphone_shoppinglist.png';
import sp_recipe from '../../assets/images/smartphone_recipe.png';

import Page from './Page';
import LoginForm from '../partial/LoginForm';
import RecipeTile from '../partial/RecipeTile';

class Start extends React.Component {    

    constructor(props) {
        super(props);

        this.state = {
            oResponse    : '',
            error        : false,
            errorMessage : '',
            loading      : true,
        }

        this.pushHistory = this.pushHistory.bind(this);
    }

    
    /**
     * After mounting, get recipes or verify user.
     * 
     * @author Lennart Bergmann
     * @created 2020-09-07
     */
    componentDidMount = () => {
        const oParams = new URLSearchParams(this.props.location.search);
        
        if(oParams.get('verify')) {
            this.verify(oParams.get('verify'));
        }
        else {
            this.getRecipes();
        }
    }


    /**
     * Verify user.
     * 
     * @author Lennart Bergmann
     * @created 2020-08-02
     * 
     * @param {string} sVerificationcode
     * 
     * @returns {undefined}
     */
    verify = sVerificationcode => {
        const sUrl = 'user';
        const oFormData = new FormData();

        oFormData.append('action', 'verify');
        oFormData.append('verificationcode', sVerificationcode);

        ajax( oFormData, sUrl)
        .then(oResponse => {
            log(oResponse, 'Start');

            if(oResponse.status === 'success') {
                // this.setState({
                //     loading   : false
                // });
                this.pushHistory('/einkaufsliste');
            }
            else {
                this.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    loading      : false                
                });
            }
        });
    }


    /**
     * Go to given page.
     * 
     * @param {string} sRoute 
     */
    pushHistory(sRoute) {
        this.props.history.push(sRoute);
    }


    /**
     * Get all Recipes for this list.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-12-06
     */
    getRecipes() {
        const self = this;

        const sUrl = 'recipes';
        const oFormData = new FormData();

        oFormData.append('action', 'get-all');
        oFormData.append('limit', 2);

        ajax( oFormData, sUrl)
        .then(function(oResponse) {

            log(oResponse, 'Start');

            if(oResponse.status === 'success') {
                self.setState({
                    oResponse : oResponse,
                    loading   : false
                });
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    loading      : false                
                });
            }
            
        });
    }


    /**
     * Display recipes.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-12-06
     * 
     * @return {JSX}
     */
    displayRecipes() {
        if(this.state.oResponse !== '') {
            const oResponse = this.state.oResponse;

            if(oResponse.status === 'success') {
                return (
                    oResponse.data.recipes.map(oRecipe => {
                        return (
                            <RecipeTile key={oRecipe.id} recipe={oRecipe} />                        
                        )                    
                    })
                );
            }
                      
        }
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-06
     * 
     * @return {JSX}
     */
    render() {

        return (
            <Page
                class           = 'start'
                error           = {false} 
                loading         = {false}
                headline        = 'Kalusto'
                hideMenu        = {true}
                errorMessage    = ''
                currentMenuItem = {'/'}
                footer          = {true}
                full_width      = {true}
            >
                <div className="hero-container">
                    <h2>Kalusto</h2>
                    <h3>Den Haushalt im Griff</h3>
                </div>                
                
                <LoginForm
                    classes = 'top'
                    pushHistory   = {this.pushHistory}
                />

                <div className="example-container color">
                    <div className="inner columns">
                        <div className="image column is-half">
                            <img src={sp_pantry} alt="Vorrat Beispiel" />
                        </div>
                        <div className="desc-container column is-half">
                            <h2>Mit dem Vorrat <i className="fas fa-home"></i> hast du immer im Blick was du schon da hast!</h2>
                            <p>Und wenn etwas ausgeht, kannst du es mit einem <i>swipe</i> nach rechts, auf die Einkaufsliste setzen - oder mit einen <i>swipe</i> nach links löschen.</p>
                        </div>
                    </div>
                </div>

                <div className="example-container reverse">
                    <div className="inner columns">
                        <div className="image column is-half">
                            <img src={sp_shoppinglist} alt="Einkaufsliste Beispiel" />
                        </div>
                        <div className="desc-container column is-half">
                            <h2>Auf der Einkaufsliste <i className="fas fa-shopping-cart"></i> siehst du nicht nur was du einkaufen musst, </h2>
                            <p> ... sondern auch wieviel und für welches Rezept.</p>
                            <p><strong>Tip:</strong> Wenn du ein Item antippst, kannst du es bearbeiten.</p>
                        </div>                        
                    </div>
                </div>

                <div className="example-container color left-slant">
                    <div className="inner columns">
                        <div className="image column is-half">
                            <img src={sp_recipe} alt="Vorrat Beispiel" />
                        </div>
                        <div className="desc-container column is-half">
                            <h2>Auch bei den Rezepten <i className="fas fa-utensils"></i> wird dir angezeigt was du hast und was du noch brauchst! Und wenn du mal nicht weißt, was du kochen sollst
                            kannst du dir einfach Rezepte anzeigen lassen für die du schon Zutaten da hast!</h2>
                        </div>
                    </div>
                </div>

                <div className="recipes-container">
                    <h2 className="recipe-header">Du kannst deine eigenen Rezepte anlegen, oder aus der Vielzahl von schon existierenden Rezepten wählen:</h2>

                    <div className="columns is-multiline">
                        {this.displayRecipes()}
                    </div>

                    <div className="more has-text-centered">
                        <Link to="/recipes">
                            <input className="button is-link" type="submit" value="Mehr Rezepte" />
                        </Link>
                    </div>
                </div>

                <div className="lf-container color">
                    <div className="inner">
                        <h2>Registrier dich jetzt!</h2>
                        <LoginForm
                            register = {true}
                            classes  = 'bottom'
                            pushHistory   = {this.pushHistory}
                        />
                    </div>
                </div>

            </Page>
        );
        
    }
}

export default Start;
