import React from 'react';
// import {Link} from 'react-router-dom';

import {ajax} from '../util/Ajax';
// import {log} from '../util/Log';
import Authenticator from '../util/Authenticator';

import Page from './Page';
import RecipeTile from '../partial/RecipeTile';
import RecipeMenu from '../partial/RecipeMenu';
import AddRecipe from '../partial/AddRecipe';


/**
 * NTH Anzeigen wie oft ein Rezept favorisiert wurde
 * NTH Autor anzeigen
 * NTH Search ingredients
 * NTH Auswählbar was durchsucht wird (title, tags, ingredients (directions?))
 * NTH Mehrere Tags erlauben, auswählbar ob && oder ||
 * NTH filter by difficulty
 */
class Recipes extends React.Component {  


    /**
     * Constructor.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-08
     */
    constructor(props) {
        super(props);

        this.state = {
            oResponse    : '',
            error        : false,
            errorMessage : '',
            loading      : true,
            page         : 0,
            tag          : '',
            search       : '',
            smrtRcpeHnt  : true,
            params       : new URLSearchParams(this.props.location.search)
        }
        this.changePage   = this.changePage.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.clearSearch  = this.clearSearch.bind(this);
    }


    /**
     * Component did mount.
     * 
     * @author Lennart Bergmann
     * @created 2021-01-07
     */
    componentDidMount() {
        this.getRecipes();
    }


    /**
     * Component did update.
     * 
     * @author Lennart Bergmann
     * @created 2020-03-20
     * @updated 2021-01-09
     * 
     * @returns {undefined}
     */
    componentDidUpdate(prevProps, prevState) {
        // Reload Recipes if url params have changed
        if((prevProps.location.search !== this.props.location.search)
        || prevProps.location !== this.props.location) {
            this.getRecipes();
        }
    }


    /**
     * Get all Recipes for this list.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-08
     * 
     * @returns {undefined}
     */
    getRecipes() {
        // NTH lazyloading?
        const self = this;

        const sUrl = 'recipes';
        const oFormData = new FormData();
        
        if(this.props.get === 'all') {
            oFormData.append('action', 'get-all');
        }
        else if(this.props.get === 'my') {
            oFormData.append('action', 'get-my-recipes');
        }
        else if(this.props.get === 'favorites') {
            oFormData.append('action', 'get-my-favorites');
        }
        else if(this.props.get === 'smart') {
            oFormData.append('action', 'get-smart-recipes');
        }

        // URL Params
        const oParams = new URLSearchParams(this.props.location.search);
        
        // Get page
        if(oParams.get('page')) {
            const iPage = parseInt(oParams.get('page'));
            if(false === isNaN(iPage)) {
                oFormData.append('page', iPage);
            }
        }

        // Get Tag/Search
        let sTag = '';
        let sSearch = '';        
        if(oParams.get('tag')) {
            sTag = oParams.get('tag');
            oFormData.append('tag', sTag);
        }
        else if(oParams.get('search')) {
            sSearch = oParams.get('search');
            oFormData.append('search', sSearch);
        }

        ajax( oFormData, sUrl)
        .then(function(oResponse) {
            if(oResponse.status === 'success') {
                self.setState({
                    oResponse : oResponse,
                    tag       : sTag,
                    search    : sSearch,
                    params    : new URLSearchParams(self.props.location.search),
                    loading   : false
                });
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    loading      : false 
                });
            }            
            window.scrollTo(0,0);
        });
    }


    /**
     * Display all recipes.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-08
     * 
     * @returns {JSX}
     */
    displayRecipes() {
        if(this.state.oResponse !== '') {
            const oResponse = this.state.oResponse;

            if('success' === oResponse.status
            && 0 < oResponse.data.recipes.length) {
                return (
                    oResponse.data.recipes.map(oRecipe => {
                        return (
                            <RecipeTile key={oRecipe.id} recipe={oRecipe} changeTag={this.changeTag.bind(this)} />
                        );
                    })
                );
            }
            else {
                return (<div className="column is-12">Keine Rezepte gefunden.</div>);
            }
        }
    }


    /**
     * Display Pagination.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-29
     * @updated 2020-06-18
     * 
     * @returns {JSX}
     */
    displayPagination() {
        let iPage = 1;
        if(this.state.params.get('page')) {
            iPage = parseInt(this.state.params.get('page'));
            if(isNaN(iPage)) {
                iPage = 1;
            }
        }
        
        if('' !== this.state.oResponse
        && 0 < this.state.oResponse.data.recipes.length
        && 1 < this.state.oResponse.data.pages) {
            return (
                <div className="pagination-container has-text-centered">
                    <button onClick={this.changePage} value={iPage - 1} className="pagination-previous" title="This is the first page" disabled={1 === iPage}>Vorherige</button>
                    <div className="select">
                        <select value={iPage} onChange={this.changePage}>
                            {this.getPaginationDropdown()}
                        </select>
                    </div>
                    <button onClick={this.changePage} value={iPage + 1} className="pagination-next" disabled={this.state.oResponse.data.pages === iPage}>Nächste</button>
                </div>
            );
        }
        else {
            return '';
        }
    }


    /**
     * Dropdown for Pagination.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-29
     * 
     * @returns {JSX}
     */
    getPaginationDropdown() {
        const aDropdown = [];
        for(let i = 1; i <= this.state.oResponse.data.pages; i++) {
            aDropdown.push(<option key={i} value={i}>{i}</option>);
        }
        return aDropdown;
    }


    /**
     * Changes the page.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * 
     * @created 2019-09-29
     * @updated 2020-03-20
     * 
     * @returns {undefined}
     */
    changePage(oEvent) {
        if(oEvent.target.value !== this.state.params.get('page')) {
            const iPage = oEvent.target.value;

            this.setState({
                page : iPage
            }, function() {
                let sUrl = this.props.location.pathname;
                const oUrlParams = new URLSearchParams(this.props.location.search);

                // Page url param doesn't exist but others do
                if(!oUrlParams.get('page')
                && (oUrlParams.get('tag') || oUrlParams.get('search'))) {
                    sUrl += this.props.location.search + '&page=' + iPage;
                }
                // No url params exist
                else if(!oUrlParams.get('page')) {
                    sUrl += this.props.location.search + '?page=' + iPage;
                }
                // Page url param exists (others may do too)
                else {
                    sUrl += this.props.location.search.replace(/page=[0-9]+/, 'page=' + iPage);
                }

                this.props.history.push(sUrl);
            });
        }
    }


    /**
     * Change Tag.
     * 
     * @author Lennart Bergmann <lb@sopg.de>
     * 
     * @created 2020-03-20
     * 
     * @param {object} oEvent 
     * 
     * @returns {undefined}
     */
    changeTag(oEvent) {
        if(oEvent.target.textContent !== this.state.tag) {
            const sTag = oEvent.target.textContent;
            this.props.history.push(this.props.location.pathname + '?tag=' + sTag);
        }
    }


    /**
     * Display search form.
     * 
     * @author Lennart Bergmann
     * @created 2020-04-01
     * 
     * @returns {JSX}
     */
    displaySearchForm() {
        if('/smart-recipes' !== this.props.location.pathname) {
            return(
                <form className="search-form" onSubmit={this.submitSearch}>
                    <div className="field is-grouped">
                        <div className="control is-expanded has-icons-left has-icons-right search-bar">
                            <input className="input" type="text" value={this.state.search} onChange={oEvent => this.setState({search: oEvent.target.value})} />
                            <span className="icon is-small is-left">
                                <i className="fas fa-search"></i>
                            </span>
                            {'' !== this.state.search ? <span className="delete" onClick={() => this.clearSearch()}></span> : ''}
                        </div>
                        <div className="control">
                            <input className="button is-link" type="submit" value="Suchen" />
                        </div>
                    </div>
                </form>
            );
        }
        return '';
    }


    /**
     * Submit search.
     * 
     * @author Lennart Bergmann
     * @created 2020-03-27
     * 
     * @returns {undefined}
     */
    submitSearch(oEvent) {
        oEvent.preventDefault();
        this.props.history.push(this.props.location.pathname + '?search=' + this.state.search);
    }


    /**
     * Clear Search.
     * 
     * @author Lennart Bergmann
     * @created 2020-03-27
     * 
     * @returns {undefined}
     */
    clearSearch() {
        this.setState({
            search : ''
        }, () => this.props.history.push(this.props.location.pathname));
    }


    /**
     * Display smart recipes hint.
     * NTH remember closing with cookies
     * 
     * @author Lennart Bergmann
     * @created 2020-04-01
     * 
     * @returns {JSX}
     */
    displaySmartRecipeHint() {
        if(true === this.state.smrtRcpeHnt
        && '/smart-recipes' === this.props.location.pathname) {
            return (
                <div className="smart-recipes-hint message is-success">
                    <div className="message-body">
                        {/* <button className="delete" onClick={() => this.setState({smrtRcpeHnt : false})}></button> */}
                        <i>Smart Recipes</i> sind Rezepte, die Zutaten verwenden, welche schon in Ihrem Vorrat sind. Je höher auf der Seite das Rezept ist, desto mehr Zutaten haben Sie schon.
                    </div>
                </div>
            );
        }
        return '';
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-06
     * 
     * @returns {JSX}
     */
    render() {
        return (
            <Page
                class = 'recipes'
                error = {this.state.error} 
                loading  = {this.state.loading}
                headline = 'Rezepte'
                hideMenu = {!Authenticator.isAuthenticated()}
                errorMessage = {this.state.errorMessage}
                currentMenuItem = '/rezepte'
            >

                { Authenticator.isAuthenticated() === true 
                    ? <RecipeMenu />
                    : ''
                }

                {this.displaySearchForm()}

                {this.displaySmartRecipeHint()}

                <div className="columns is-multiline recipes-container">
                    {this.displayRecipes()}
                </div>
                
                {this.displayPagination()}

                {Authenticator.isAuthenticated() === true ? 
                    <AddRecipe to="/neues-rezept" />
                    : ''
                }
            </Page>
        );
    }
}

export default Recipes;
