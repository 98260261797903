
/**
 * Logs given message.
 * 
 * @author Lennart Bergmann <mail@lennartbergmann.com>
 * @created 2019-09-06
 * 
 * @param {string} sMessage
 * @param {string} sLocation
 * @param {bool} bForce = false
 */
function log(sMessage, sLocation = 'Log', bForce = false) {

    if(bForce === true || process.env.REACT_APP_LOG === 'true') {
        console.log('[' + sLocation + '] ', sMessage);
    }
}

export { log };