
/**
 * Get unit.
 * 
 * @author Lennart Bergmann <mail@lennartbergmann.com>
 * @created 2019-09-28
 * 
 * @param {string}
 * 
 * @return {string}
 */
function getUnit(sUnit) {
    switch(sUnit) {
        case 'g':
            return 'g';
        case 'kg':
            return 'kg';
        case 'ml':
            return 'ml';
        case 'l':
            return 'l';
        case 'item':
            return ' Stück';
        case 'can':
            return ' Dose';
        case 'pack':
            return ' Packung';
        case 'tl':
            return ' TL';
        case 'el':
            return ' EL';
        case 'pinch':
            return ' Prise';
        case 'handfull':
            return ' Handvoll';
        case 'slices':
            return ' Scheiben';
        default :
            return '';
    }
}

export { getUnit };