import React from 'react';
// import {log} from '../util/Log';

import Item from './Item';
import LocalStorageHelper from '../util/LocalStorage';

class CategoryItem extends React.Component {


    /**
     * Constructor.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-05-23
     * @updated 2020-06-28
     * 
     * @param {object}
     * 
     * @returns {undefined}
     */
    constructor(props) {
        super(props);

        this.state = {
            numItems : this.props.items.length,
            closed   : !!LocalStorageHelper.getItem(this.props.id)
        };

        this.container = null;
        this.containerTransition = 300;
        this.containerHeight = 0;
        this.itemHeight = 55;
        this.closedHeight = 39;
        this.editingHeight = 600;

        this.editing = false;
    }


    /**
     * Component did mount.
     * 
     * @author Lennart Bergmann
     * @created 2020-05-23
     * @updated 2020-06-28
     * 
     * @return {undefined}
     */
    componentDidMount = () => {
        this.container = document.getElementById(this.props.id);
        this.containerHeight = this.container.clientHeight;
        this.container.style.height = 'auto'; 

        if(this.state.closed) {
            this.setContainerHeight(this.closedHeight);
        }
    }


    /**
     * Call toggleEditMode of ItemList.
     * 
     * @author Lennart Bergmann
     * @created 2020-02-11
     * @updated 2020-06-09
     * 
     * @returns {undefined}
     */
    toggleEditMode() {
        this.props.toggleEditMode();
        if(false === this.editing) {
            this.editing = true;
        }
        else {
            this.editing = false;
        }
    }


    /**
     * Get <Item>
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-11-24
     * @updated 2020-06-01
     * 
     * @param {object} oItem 
     * 
     * @returns {JSX}
     */
    getItem(oItem) {
        return (
            <Item 
                key = {oItem.id} 
                item = {oItem} 
                status = {this.props.location} 
                toggleEditMode = {this.toggleEditMode.bind(this)} 
                removeFromRecipe = {this.removeItem.bind(this)}
                isIngredient = {oItem.recipe ? true : false}
                removeItem = {this.props.removeItem}
            />
        );
    }


    /**
     * Display the items.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-02-11
     * 
     * @returns {JSX}
     */
    displayItems() {
        return this.props.items.map(oItem => {
            return this.getItem(oItem);
        });        
    }


    /**
     * Reduce numItems by one, update container height.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-02-11
     * @updated 2020-06-09
     * 
     * @returns {undefined}
     */
    removeItem() {
        this.setState({
            numItems: this.state.numItems - 1
        });

        this.containerHeight -= this.itemHeight;
    }


    /**
     * Toggles closed state.
     * 
     * @author Lennart Bergmann
     * @created 2020-05-23
     * @updated 2020-06-28
     * 
     * @returns {undefined}
     */
    toggleClosed = () => {
        // Open it
        if(this.state.closed) {
            LocalStorageHelper.removeItem(this.props.id);
            this.setContainerHeight(this.containerHeight);
            setTimeout(() => {
                this.container.style.height = 'auto';
            }, this.containerTransition);
        }
        // Close it
        else {
            LocalStorageHelper.setItem(this.props.id, 1);
            this.setContainerHeight(this.containerHeight);
            setTimeout(() => {
                this.setContainerHeight(this.closedHeight);
            }, 10);
        }

        this.setState({
            closed : !this.state.closed
        });
    }


    /**
     * Set Container height.
     * 
     * @author Lennart Bergmann
     * @created 2020-05-23
     * 
     * @param {int} iHeight, height in px
     * 
     * @returns {undefined}
     */
    setContainerHeight = (iHeight) => {
        this.container.style.height = iHeight + 'px';
    }


    /**
     * Display title.
     * 
     * @author Lennart Bergmann
     * @created 2020-05-23
     * @updated 2020-05-24
     * 
     * @returns {undefined}
     */
    displayTitle = () => {
        if(this.props.isRecipe) {
            return (
                <React.Fragment>
                    <a href={"recipe/" + this.props.url}>
                        <img className="chef-icon" src={require('../../assets/images/icons/recipes.png')} alt="Rezept" />
                        {this.props.title} ({this.state.numItems})
                    </a>
                    {this.state.closed ? <i className="fas fa-angle-left"></i> : <i className="fas fa-angle-down"></i> }
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    {this.props.title} ({this.state.numItems}) {this.state.closed ? <i className="fas fa-angle-left"></i> : <i className="fas fa-angle-down"></i> }
                </React.Fragment>
            );
        }
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-05-23
     * 
     * @returns {JSX}
     */
    render() {
        return (
            <div id={this.props.id} className={"category-container" + (0 < this.state.numItems ? "" : " hide")}>
                <h2 onClick={() => this.toggleClosed()}>
                    {this.displayTitle()}
                </h2>
                {this.displayItems()}
            </div>
        );
    }
}

export default CategoryItem;