import React from 'react';
import {Link} from 'react-router-dom';

/**
 * TODO die ganzen Seiten anlegen
 */
const Footer = () => {    

    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-03-12
     * @updated 2020-04-19
     * 
     * @return {JSX}
     */
    return (
        <footer>
            <div className="columns inner">
                <div className="column">
                    kalusto.de (c) 2020
                </div>
                <div className="column">
                    <div>Unternehmen</div>
                    <div><Link to="/ueber">Über</Link></div>
                    <div><Link to="/changelog">Changelog</Link></div>
                    <div><Link to="/roadmap">Roadmap</Link></div>
                </div>
                <div className="column">
                    <div>Rechtliches</div>
                    <div><Link to="/datenschutz">Datenschutz</Link></div>
                    <div><Link to="/impressum">Impressum</Link></div>
                    <div><Link to="/agb">AGB</Link></div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;