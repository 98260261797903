import React from 'react';

const UnitSelect = props => {    

    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-10
     * @updated 2020-04-19
     * 
     * @return {JSX}
     */
    return (
        <select id={props.id} value={props.value} onChange={props.onChange} >
            <option value="g">g</option>
            <option value="kg">kg</option>
            <option value="ml">ml</option>
            <option value="l">l</option>
            <option value="item">Stück</option>
            <option value="slices">Scheibe/n</option>
            <option value="can">Dose/n</option>
            <option value="pack">Packung/en</option>
            <option value="el">EL</option>
            <option value="tl">TL</option>
            <option value="whole">Ganze</option>
            <option value="bundle">Bund</option>
            <option value="clove">Zehe/n</option>
            <option value="pinch">Prise/n</option>
            <option value="handfull">Handvoll</option>
        </select>
    );
}

export default UnitSelect;