import React from 'react';

import {ajax} from '../util/Ajax';
import {log} from '../util/Log';

import Item from '../partial/Item';
import AddItem from '../partial/AddItem';

import Page from './Page';
import ItemForm from '../partial/ItemForm';
import CategoryItem from '../partial/CategoryItem';
import { getCategories } from '../util/Categories';
import LocalStorageHelper from '../util/LocalStorage';


/**
 * Item list.
 * 
 * @author Lennart Bergmann
 * @updated 2021-01-09
 */
class ItemList extends React.Component {    


    /**
     * Constructor.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-06
     * @updated 2020-09-09
     */
    constructor(props) {
        super(props);

        this.state = {
            oResponse     : null,
            data          : null,
            location      : this.props.location.pathname.substr(1),
            error         : false,
            errorMessage  : '',
            loading       : true,
            hideItemForm  : true,
            hideAddButton : false,
            posMenuFixed  : true,
            alphabetical  : !!LocalStorageHelper.getItem('itemlist-alphabetical')
        }

        this.addItem        = this.addItem.bind(this);
        this.toggleForm     = this.toggleForm.bind(this);
        this.toggleEditMode = this.toggleEditMode.bind(this);
    }


    /**
     * Component did mount.
     * 
     * @author Lennart Bergmann
     * @created 2021-01-07
     */
    componentDidMount() {
        this.getItems();
    }


    /**
     * Component did update.
     * 
     * @author Lennart Bergmann
     * @created 2021-01-09
     * 
     * @returns {undefined}
     */
    componentDidUpdate(prevProps, prevState) {
        // Reload Items if url params have changed
        if(prevProps.location !== this.props.location) {
            this.getItems();
        }
    }


    /**
     * Get all items for this list.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-06
     * @updated 2021-01-09
     */
    getItems() {
        let location = 'pantry';
        if('einkaufsliste' === this.props.location.pathname.substr(1)) {
            location = 'shopping-list';
        }

        const oFormData = new FormData();
        oFormData.append('type', location);
        oFormData.append('action', 'getAll');

        ajax( oFormData, 'items')
        .then((oResponse) => {

            if(oResponse.status === 'success') {
                this.setState({
                    oResponse : oResponse,
                    data      : oResponse.data,
                    loading   : false,
                    location  : this.props.location.pathname.substr(1)
                });
            }
            else {
                this.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    loading      : false
                });
            }
        });
    }


    /**
     * Display all items.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-07
     * @updated 2020-09-09
     * 
     * @return {JSX}
     */
    displayItems() {
        if(null === this.state.data) {
            return;
        }

        const aData = this.state.data;
        let sCategory = 'Sonstiges';
        let iCategory = 100000;
        let aItems = [];

        let oCategories = getCategories();

        if(true === this.state.alphabetical) {
            aItems[0] = {
                items    : [],
                category : 'Alles',
                id       : 'container-0'
            };
            for(let i = 0; i < aData.length; i++) {
                aItems[0].items.push(
                    (aData[i])
                );
            }
        }
        else {
            for(let i = 0; i < aData.length; i++) {
                sCategory = 'Sonstiges';
                iCategory = 100000;
                if(aData[i].category && oCategories[aData[i].category]) {
                    iCategory = aData[i].category;
                    sCategory = oCategories[aData[i].category];
                }
    
                if(undefined === aItems[iCategory]) {
                    aItems[iCategory] = {
                        items    : [],
                        category : sCategory,
                        id       : 'container-' + aData[i].id
                    };
                }
    
                aItems[iCategory].items.push(
                    (aData[i])
                );
            }
        }

        return aItems.map(oItem => {
            return (
                <CategoryItem 
                    id = {oItem.id} 
                    key = {oItem.id}
                    location = {this.state.location}
                    title = {oItem.category} 
                    items = {oItem.items} 
                    toggleEditMode = {this.toggleEditMode}
                    removeItem = {this.removeItem}
                />
            );
        });
    }


    /**
     * Display the recipes.
     * 
     * @author Lennart Bergmann
     * @created 2020-05-22
     * @updated 2020-06-01
     * 
     * @return {JSX}
     */
    displayRecipes = () => {
        if(null === this.state.data) {
            return;
        }

        const aData = this.state.data;
        let iLastRecipe = null;
        let aIngredients = [];

        for(let i = 0; i < aData.length; i++) {
            /* Same recipe as last item */
            if(null !== aData[i].recipe
            && iLastRecipe === aData[i].recipe) {
                aIngredients[aData[i].recipe].ingredients.push(
                    // this.getItem(aData[i])
                    aData[i]
                );
            }
            /* New recipe */
            else if(null !== aData[i].recipe) {                    
                aIngredients[aData[i].recipe] = {
                    recipeTitle : aData[i].title,
                    url         : aData[i].url,
                    ingredients : [],
                    id          : 'container-' + aData[i].id
                };
                aIngredients[aData[i].recipe].ingredients.push(
                    // this.getItem(aData[i])
                    aData[i]
                );
            }
            iLastRecipe = aData[i].recipe;
        }

        return aIngredients.map(oItem => {
            return (
                <CategoryItem 
                    id = {oItem.id} 
                    key = {oItem.id}
                    url = {oItem.url} 
                    location = {this.state.location}
                    title = {oItem.recipeTitle} 
                    items = {oItem.ingredients} 
                    toggleEditMode = {this.toggleEditMode}
                    isRecipe = {true}
                    removeItem = {this.removeItem}
                />
            )
        });
    }


    /**
     * Remove item from state.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-01
     * 
     * @param {object} oItem
     * 
     * @return {undefined}
     */
    removeItem = (oItem) => {
        log('remove Item', 'itemlist');
        const aData = this.state.data;
        const iIndex = aData.indexOf(oItem);
        if(-1 < iIndex) {
            aData.splice(iIndex, 1);
        }

        this.setState({
            data: aData
        });
    }


    /**
     * Get <Item>
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-11-24
     * 
     * @param {object} oItem 
     * 
     * @return {JSX}
     */
    getItem(oItem) {
        return (
            <Item key={oItem.id} status={this.state.location} item={oItem} toggleEditMode={this.toggleEditMode} />
        );
    }


    /**
     * Display hint for swiping.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-07
     * 
     * @return {JSX}
     */
    displayHint() {
        if(this.state.location === 'shopping-list') {
            return(
                <div className="hint">
                    <div className="left">
                        <span className="icon has-text-black">
                            <i className="fas fa-lg fa-home"></i>
                        </span>
                        <span className="icon has-text-black">
                            <i className="fas fa-lg fa-long-arrow-alt-left"></i>
                        </span>
                        {/* <img src={require('../../assets/images/icons/pantry.png')} alt="Vorrat" /> */}
                        {/* <strong>&larr;</strong> <br/> */}
                    </div>
                    <div className="right">
                    <span className="icon has-text-black">
                            <i className="fas fa-lg fa-long-arrow-alt-right"></i>
                        </span>
                        <span className="icon has-text-black">
                            <i className="fas fa-lg fa-trash"></i>
                        </span>
                        {/* <img src={require('../../assets/images/icons/trash.png')} alt="Löschen" /> */}
                    </div>
                </div>
            )
        }
        else if(this.state.location === 'pantry') {
            return (
                <div className="hint">
                    <div className="left">
                        <span className="icon has-text-black">
                            <i className="fas fa-lg fa-trash"></i>
                        </span>
                        <span className="icon has-text-black">
                            <i className="fas fa-lg fa-long-arrow-alt-left"></i>
                        </span>
                        {/* <strong>&larr;</strong><br/> */}
                    </div>
                    <div className="right">
                        {/* <strong>&rarr;</strong> */}
                        <span className="icon has-text-black">
                            <i className="fas fa-lg fa-long-arrow-alt-right"></i>
                        </span>
                        <span className="icon has-text-black">
                            <i className="fas fa-lg fa-shopping-cart"></i>
                        </span>
                        {/* <img src={require('../../assets/images/icons/shopping-list.png')} alt="Einkaufsliste" /> */}
                    </div>
                </div>
            )
        }
        else {
            return '';
        }
    }


    /**
     * Add item.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-11-15
     * @updated 2020-05-21
     * 
     * @param {object} oData
     * 
     * @returns {undefined}
     */
    addItem(oData) {
        const oItem = {
            product   : oData.product,
            amount    : oData.amount,
            unit      : oData.unit,
            category  : oData.category,
            type      : oData.type,
            aggregate : oData.aggregate,
            special   : oData.special,
            status    : oData.add_to,
            id        : oData.id,
            recipe    : null
        };

        const oResponse = this.state.oResponse;
        oResponse.data.unshift(oItem);

        this.setState({
            oResponse : oResponse
        });
    }


    /**
     * Display item form
     */
    displayItemForm() {
        if(this.state.hideItemForm === true) {
            return '';
        }
        else {
            return (
                <ItemForm 
                    list={this.props.location.pathname.substr(1)} 
                    hideForm={this.toggleForm}
                    submitCallback={this.addItem}
                    id = 'lonely-item-form'
                />
            );
        }
    }


    /**
     * Toggle edit mode of item
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-11-24 
     */
    toggleEditMode() {
        this.setState({
            hideAddButton : !this.state.hideAddButton,
            posMenuFixed  : !this.state.posMenuFixed
        });
    }


    /**
     * Toggle form
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-11-24 
     */
    toggleForm() {
        this.toggleEditMode();

        this.setState({
            hideItemForm  : !this.state.hideItemForm
        });
    }


    /**
     * Toggle alphabetical view.
     * 
     * @author Lennart Bergmann
     * @created 2020-09-09
     */
    toggleAlphabetical = () => {
        if(true === this.state.alphabetical) {
            LocalStorageHelper.removeItem('itemlist-alphabetical');
        }
        else {
            LocalStorageHelper.setItem('itemlist-alphabetical', 1);
        }
        this.setState({
            alphabetical : !this.state.alphabetical
        });
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-06
     * @updated 2021-01-02
     * 
     * @return {JSX}
     */
    render() {
        return (
            <Page 
                class              = {"item-list" + (this.state.hideItemForm ? '' : ' show-form')}
                error              = {this.state.error} 
                errorMessage       = {this.state.errorMessage}
                loading            = {this.state.loading}
                headline           = {this.state.location === 'vorrat' ? 'Vorrat' : 'Einkaufsliste'}
                currentMenuItem    = {this.state.location === 'vorrat' ? '/vorrat' : '/einkaufsliste'}
                posMenuFixed       = {this.state.posMenuFixed}
                toggleAlphabetical = {this.toggleAlphabetical.bind(this)}
                alphabetical       = {this.state.alphabetical}
                >
                <div className="items-container">
                    {this.displayItemForm()}

                    {this.displayItems()}
                    {this.displayRecipes()}
                    {this.displayHint()}

                    {this.state.hideAddButton ? '' : 
                        <AddItem toggleForm={this.toggleForm} />
                    }
                </div>
            </Page>
        );
    }
}

export default ItemList;