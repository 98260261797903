/**
 * Checks if a value is null or 'null'
 * returns value otherwise.
 * 
 * @author Lennart Bergmann 
 * @created 2020-06-30
 * 
 * @param {mixed}
 * 
 * @returns {mixed}
 */
function isNull($mValue) {
    if('null' === $mValue
    || null === $mValue) {
        return '';
    }
    return $mValue;
}

export { isNull };