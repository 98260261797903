
/**
 * Ajax.
 * 
 * @author Lennart Bergmann <mail@lennartbergmann.com>
 * @created 2019-09-07
 */
function ajax(data, url) {

    return fetch(process.env.REACT_APP_AJAX_URL + url, {
        method: 'POST',
        body: data,
        mode: 'cors',
        credentials : 'same-origin'
    })
    .then(function(sResponse) {
        return sResponse.json();
    });
}

export { ajax };