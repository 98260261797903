import React from 'react';
import {Link} from 'react-router-dom';

import {ajax} from '../util/Ajax';
// import {log} from '../util/Log';
import Authenticator from '../util/Authenticator';

import Ingredient from '../partial/Ingredient';
import Direction from '../partial/Direction';
import RecipeMenu from '../partial/RecipeMenu';

import Page from './Page';


/**
 * NTH back button für nicht eingeloggte user
 * NTH cals, time, difficulty, tags anzeigen (cals berechnen mit serves nicht vergessen)
 * NTH bei nicht eingeloggten Nutzern verschieben der Ingredients nicht erlauben
 * NTH Höhe der Seite immer gleich lassen, nicht ändern bei wechsel zwischen zutaten/anleitung
 */
class SingleRecipe extends React.Component {    


    /**
     * Constructor.
     * 
     * @author Lennart Bergmann
     * 
     * @created 2019-09-17
     * @updated 2021-01-09
     */
    constructor(props) {
        super(props);

        this.state = {
            ingredients  : [],
            directions   : [],
            recipe       : '',
            error        : false,
            errorMessage : '',
            favorite     : false,
            editable     : false,
            loading      : true,
            tab          : 'ingredients',
            serves       : '',
            origServes   : '',
            notFound     : false,
            nFMessage    : ''
        };

        this.displayTitle        = this.displayTitle.bind(this);
        this.addToFavorites      = this.addToFavorites.bind(this);
        this.removeFromFavorites = this.removeFromFavorites.bind(this);
    }

    /**
     * Component did mount.
     * 
     * @author Lennart Bergmann
     * @created 2021-01-07
     */
    componentDidMount() {
        this.getIngredients();
    }


    /**
     * Get ingredients.
     * 
     * @author Lennart Bergmann 
     * 
     * @created 2019-09-17
     * @updated 2021-01-09
     */
    getIngredients() {
        const oFormData = new FormData();
        oFormData.append('action', 'get-single-recipe');
        oFormData.append('url', this.props.match.params.title);

        ajax(oFormData, 'recipes')
        .then((oResponse) => {
            if('success' === oResponse.status) {
                this.setState({
                    ingredients : oResponse.data.ingredients,
                    directions  : oResponse.data.directions,
                    recipe      : oResponse.data.recipe,
                    favoritable : oResponse.data.favoritable,
                    favorite    : oResponse.data.favorite,
                    editable    : oResponse.data.editable,
                    serves      : parseInt(oResponse.data.recipe.serves),
                    origServes  : parseInt(oResponse.data.recipe.serves),
                    loading     : false
                });
            }
            else if('not-found' === oResponse.status) {
                console.log('not-found');
                this.setState({
                    notFound  : true,
                    nFMessage : oResponse.message,
                    loading   : false
                });
            }
            else {
                this.setState({
                    error   : true,
                    errorMessage : oResponse.message,
                    loading : false
                });
            }
        });
    }


    /**
     * Display ingredients.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-17
     * 
     * @return {JSX}
     */
    displayIngredients() {            
        const aRow = [];

        for(let i = 0; i < this.state.ingredients.length; i++) {
            aRow.push(
                <Ingredient 
                    key={'ingredient-' + i} 
                    data={this.state.ingredients[i]} 
                    recipeId ={this.state.recipe.id} 
                    serves={this.state.serves} 
                    origServes={this.state.origServes} 
                />
            );
        }

        return aRow;         
    }


    /**
     * Display the title.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-28
     * 
     * @return {JSX}
     */
    displayTitle() {       
        return (
            <h2 className="title">{this.state.recipe.title}</h2>
        );
    }


    /**
     * Add recipe to favorites.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-28
     */
    addToFavorites() {
        const oFormData = new FormData();
        const self = this;
        oFormData.append('action', 'add-to-favorites');
        // oFormData.append('id', this.props.location.state.recipe.id);
        oFormData.append('recipe', this.state.recipe.id);

        ajax(oFormData, 'recipes')
        .then(function(oResponse) {

            if(oResponse.status === 'success') {
                self.setState({
                    favorite    : true
                });
            }
            else {
                self.setState({
                    favoriteError   : true,
                    favoriteErrMessage : oResponse.message
                });
            }
        });
    }


    /**
     * Remove recipe from favorites.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-28
     */
    removeFromFavorites() {
        const oFormData = new FormData();
        const self = this;
        oFormData.append('action', 'remove-from-favorites');
        // oFormData.append('id', this.props.location.state.recipe.id);
        oFormData.append('recipe', this.state.recipe.id);

        ajax(oFormData, 'recipes')
        .then(function(oResponse) {

            if(oResponse.status === 'success') {
                self.setState({
                    favorite    : false
                });
            }
            else {
                self.setState({
                    favoriteError   : true,
                    favoriteErrMessage : oResponse.message
                });
            }
        });
    }


    /**
     * Display Favorite icon.
     * 
     * @author Lennart Bergmann 
     * @created 2019-09-28
     * 
     * @return {JSX}
     */
    displayFavorite() {
        if(this.state.favorite) {
            return (
                <div onClick={this.removeFromFavorites} className="favorite">
                    <img src={require('../../assets/images/icons/favorite.png')} alt="Favorite" />
                </div>
            )
        }

        return (
            <div onClick={this.addToFavorites} className="favorite">
                <img src={require('../../assets/images/icons/non-favorite.png')} alt="Nicht Favorite" />
            </div>
        )
    }


    /**
     * Display directions.
     * 
     * @author Lennart Bergmann
     * @created 2019-10-02
     * 
     * @return {JSX}
     */
    displayDirections() {
        const aRow = [];

        for(let i = 0; i < this.state.directions.length; i++) {
            aRow.push(<Direction key={'direction-' + i} data={this.state.directions[i]} />);
        }

        return aRow;
    }


    /**
     * Get publishing date.
     * 
     * @author Lennart Bergmann
     * @created 2020-07-12
     * 
     * @returns {JSX}
     */
    getPublishingDate = () => {
        let aDate = this.state.recipe.date.split(' ');

        return aDate[0];
    }


    /**
     * Get ingredients as string.
     * 
     * @author Lennart Bergmann
     * @created 2020-07-12
     * 
     * @returns {JSX}
     */
    getMetaIngredients = () => {
        let sIngredients = '["';

        for(let i = 0; i < this.state.ingredients.length; i++) {
            sIngredients += this.state.ingredients[i].amount + this.state.ingredients[i].unit + ' ' + this.state.ingredients[i].name + '", ';
        }

        sIngredients += ']';

        return sIngredients;
    }


    /**
     * Get directions as string.
     * 
     * @author Lennart Bergmann
     * @created 2020-07-12
     * 
     * @returns {JSX}
     */
    getMetaDirections = () => {
        let sDirections = '';

        for(let i = 0; i < this.state.directions.length; i++) {
            sDirections += this.state.directions[i].text + ' ';
        }

        return sDirections;
    }


    /**
     * Get recipe for meta tag.
     * 
     * @author Lennart Bergmann
     * @created 2020-07-12
     * @updated 2021-01-09
     * 
     * @returns {JSX}
     */
    getMetaRecipe = () => {
        if(this.state.loading || this.state.error || this.state.notFound) {
            return null;
        }
        
        const oRecipe = {
            published   : this.getPublishingDate(),
            image       : this.state.recipe.image,
            title       : this.state.recipe.title,
            ingredients : this.getMetaIngredients(),
            directions  : this.getMetaDirections()
        };

        return oRecipe;
    }


    /**
     * Display the recipe.
     * 
     * @author Lennart Bergmann
     * @created 2021-01-09
     * 
     * @returns {JSX}
     */
    displayRecipe = () => {
        const oTo = {
            pathname : '/rezept-bearbeiten/' + this.state.recipe.url,
        };

        return(
            <React.Fragment>
                { Authenticator.isAuthenticated() === true 
                    ? <RecipeMenu />
                    : ''
                }
                <div className="recipe-container">                   

                    <div className="meta">
                        {this.state.favoritable ? 
                            this.displayFavorite()
                        : ''}

                        {this.state.editable ? 
                            <Link to={oTo} className="edit">
                                <img src={require('../../assets/images/icons/edit.png')} alt="Bearbeiten" />
                            </Link>
                        : '' }
                        {this.displayTitle()}
                    </div>

                    <div className="image-container">
                        <picture>
                            <source srcSet={process.env.REACT_APP_UPLOADS_URL + this.state.recipe.image + '_L.jpg'} media="(min-width: 768px)" />
                            <source srcSet={process.env.REACT_APP_UPLOADS_URL + this.state.recipe.image + '_M.jpg'} media="(min-width: 425px)" />
                            <img src={process.env.REACT_APP_UPLOADS_URL + this.state.recipe.image + '_S.jpg'} alt={this.state.recipe.title} />
                        </picture>
                    </div>
                    
                    {/* {this.state.favoriteError === true ? this.state.favoriteErrMessage : ''} */}
                    
                    <div className="inner">
                        <div className="tabs tab-container">
                            <ul>
                                <li className={'ingredients' === this.state.tab ? 'is-active' : '' }>
                                    <button onClick={oEvent => this.setState({tab: 'ingredients'})}>Zutaten</button>
                                </li>
                                <li className={'directions' === this.state.tab ? 'is-active' : '' }>
                                    <button onClick={oEvent => this.setState({tab: 'directions'})}>Anleitung</button>
                                </li>
                            </ul>
                        </div>

                        {'ingredients' === this.state.tab ?
                            <div>
                                <div className="serves">
                                    <span className="serves-icon" onClick={oEvent => this.setState({serves : this.state.serves - 1})}>
                                        <i className="fas fa-minus"></i>
                                    </span>
                                    <span className="portions">{this.state.serves}</span>
                                    <span className="serves-icon" onClick={oEvent => this.setState({serves : this.state.serves + 1})}>
                                        <i className="fas fa-plus"></i>
                                    </span>
                                </div>
                            
                                <div className="ingredients-container">
                                    {this.displayIngredients()}
                                </div>
                            </div>
                        :
                            <div className="directions-container">
                                {this.displayDirections()}
                            </div>      
                        }              
                    </div>
                </div>
            </React.Fragment>
        );
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-06
     * @updated 2021-01-09
     * 
     * @return {JSX}
     */
    render() {
        return (
            <Page
                class = 'single-recipe'
                error = {this.state.error} 
                loading = {this.state.loading}
                headline = 'Rezept'
                title = {this.state.recipe.title}
                hideMenu = {!Authenticator.isAuthenticated()}
                errorMessage = {this.state.errorMessage}
                currentMenuItem = '/rezepte'
                recipe = {this.getMetaRecipe()}
            >

                {true === this.state.notFound ? this.state.nFMessage : this.displayRecipe()}
                
            </Page>
        );
    }
}

export default SingleRecipe;
