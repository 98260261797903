import React from 'react';

import {getUnit} from '../util/Unit';
// import {log} from '../util/Log';
import {ajax} from '../util/Ajax';


class Ingredient extends React.Component {    

    constructor(props) {
        super(props);

        this.state = {
            onShoppingList : this.isOnShoppingList(),
            moving : false,
            height : 45 + this.isOnShoppingListOrInPantry()
        };

        this.dragElement = this.dragElement.bind(this);
        this.displayStock = this.displayStock.bind(this);
        this.movedVertical = false;
    }


    /**
     * Check if ingredient is already on shopping list for this recipe.
     * 
     * @author Lennart Bergmann
     * @created 2019-10-04
     * 
     * @return {bool}
     */
    isOnShoppingList() {
        let bIsOnShoppingList = false;

        if(true === this.props.data.hasOwnProperty('pantry')) {
            for(let i = 0; i < this.props.data.pantry.length; i++) {
                if(this.props.data.pantry[i].onSL === this.props.recipeId) {
                    bIsOnShoppingList = true;
                    break;
                }
            }
        }

        return bIsOnShoppingList;
    }


    /**
     * Return additional height if ingredient is already 
     * on shopping list or in pantry.
     * 
     * @author Lennart Bergmann 
     * @created 2019-10-06
     * 
     * @return {int}
     */
    isOnShoppingListOrInPantry() {
        let iHeight = 0;

        if(true === this.props.data.hasOwnProperty('pantry')
        && this.props.data.pantry[0].product !== null) {
            this.props.data.pantry.forEach(function(oElement){
                iHeight += 20;
            });
        }

        return iHeight;
    }


    /**
     * Make this ingredient draggable.
     * Call move if threshold was passed.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-18
     * @updated 2020-12-23
     * 
     * @param {object} oEvent
     */
    dragElement(oEvent) {
        const oElement = oEvent.target;

        let iMouseX = oEvent.clientX;
        let iMouseYOrig = oEvent.clientY;

        oElement.style.transition = null;

        document.ontouchend = document.onmouseup = () => {
            document.onmouseup = null;
            document.onmousemove = null;
            document.ontouchend = null;
            document.ontouchmove = null;

            /* Reset moved. */
            this.movedVertical = false;

            oElement.style.transition = 'all .3s';

            if(oElement.offsetLeft * (-1) > oElement.parentNode.offsetWidth * 0.4) {

                oElement.style.left = '-104%';

                this.setState({
                    moving : true
                });

                this.addItemToShoppingList(oElement);            

            }
            else {
                oElement.style.left = 0;
            }
        };

        document.onmousemove = oMouseMoveEvent => {
            oMouseMoveEvent.preventDefault();
            iMouseX = this.drag(iMouseX, iMouseYOrig, oMouseMoveEvent.clientX, oMouseMoveEvent.clientY, oElement);
        };

        document.ontouchmove = oTouchMoveEvent => {
            iMouseX = this.drag(iMouseX, iMouseYOrig, oTouchMoveEvent.touches[0].clientX, oTouchMoveEvent.touches[0].clientY, oElement);
        }
    }


    /**
     * Drag item.
     * 
     * @author Lennart Bergmann
     * @created 2020-12-23
     * 
     * @param {int} iMouseX 
     * @param {int} iMouseY 
     * @param {int} iClientX 
     * @param {int} iClientY 
     * @param {object} oElement 
     * 
     * @returns {int}
     */
    drag(iMouseX, iMouseY, iClientX, iClientY, oElement) {
        this.moved = true;

        /* Don't move if scroll is vertical. */
        if(this.movedVertical) {
            return;
        }

        /* Don't move item if the scroll is vertical */
        let iMouseNowY = iMouseY - iClientY;
        let iDiffY = Math.abs(iMouseNowY);
        if(iDiffY > 30) {
            this.movedVertical = true;
            return;
        }

        /* Move the item */
        let iMouseNowX = iMouseX - iClientX;
        oElement.style.left = (oElement.offsetLeft - iMouseNowX) + 'px'; 

        return iClientX;
    }


    /**
     * Add this item to shopping list.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-18
     * @updated 2020-12-31
     * 
     * @param {object} oNode
     */
    addItemToShoppingList(oNode) {
        const self = this;
        const sUrl = 'items';
        const oFormData = new FormData();

        oFormData.append('action', 'add-item');
        oFormData.append('product', this.props.data.name);
        oFormData.append('amount', this.props.data.amount);
        oFormData.append('unit', this.props.data.unit);
        oFormData.append('category', this.props.data.category ? this.props.data.category : '');
        oFormData.append('type', this.props.data.type ? this.props.data.type : '');
        oFormData.append('aggregate', this.props.data.aggregate ? this.props.data.aggregate : '');
        oFormData.append('special', this.props.data.special ? this.props.data.special : '');
        oFormData.append('note', '');
        oFormData.append('importance', '');
        oFormData.append('add_to', 'shopping-list');
        oFormData.append('recipeId', this.props.recipeId);

        ajax( oFormData, sUrl)
        .then(function(oResponse) {
            self.setState({
                moving : false
            });

            if(oResponse.status === 'success') {
                oNode.style.minHeight = 0;
                oNode.style.height = 0;
                oNode.style.padding = 0;
                oNode.style.margin = 0;
                oNode.classList.add('moved');
            }
            else {
                oNode.style.left = 0;
                self.setState({
                    addError : true,
                    addErrorMessage : oResponse.message
                })
            }

        });
    }


    /**
     * Display stock.
     * 
     * @author Lennart Bergmann 
     * @created 2019-09-28
     */
    displayStock() {
        /* Only show stock if user is logged in. */
        if(false === this.props.data.hasOwnProperty('pantry')) {
            return '';
        }
        
        /* If there is no stock, don't display anything. */
        if(this.props.data.pantry.length === 1 
        && this.props.data.pantry[0].product === null) {
            return ('');
        }

        return (
            this.props.data.pantry.map(oIngredient => {

                /* If on shopping-list */
                if('shopping-list' === oIngredient.status) {
                    if(null !== oIngredient.recipe
                    && oIngredient.recipe !== this.props.recipeId) {
                        return (
                            <div key={oIngredient.id} className="not-enough">
                                <span>Für ein anderes Rezept auf deiner Einkaufsliste.</span>
                            </div>
                        );
                    }
                    else {
                        return (
                            <div key={oIngredient.id} className="enough">
                                <span>Schon auf deiner Einkaufsliste.</span>
                            </div>
                        );
                    }
                }
                /* If in pantry and the units match */
                else if(oIngredient.unit === this.props.data.unit) {
                    if(parseFloat(oIngredient.amount) >= this.calculateAmount()) {
                        return (
                            <div key={oIngredient.id} className="enough">
                                <span>Du hast {oIngredient.amount}{getUnit(oIngredient.unit)}</span>
                            </div>
                        );
                    }
                    else if('' !== oIngredient.unit) {
                        return (
                            <div key={oIngredient.id} className="not-enough">
                                <span>Du hast {oIngredient.amount}{getUnit(oIngredient.unit)}</span>
                            </div>
                        );
                    }
                }
                /* If units dont match */
                else {
                    if(null === oIngredient.amount
                    || '' === oIngredient.unit) {
                        return(
                            <div key={oIngredient.id} className="uncertain">
                                <span>Du hast eine unspezifizierte Menge.</span>
                            </div>
                        );
                    }
                    else {
                        return(
                            <div key={oIngredient.id} className="uncertain">
                                <span>Du hast {oIngredient.amount}{getUnit(oIngredient.unit)}</span>
                            </div>
                        );
                    }
                }
                return '';
            })
        );
    }


    /**
     * Calculates the amount with respect to servings.
     * 
     * @author Lennart Bergmann
     * @created 2020-03-11
     * 
     * @returns {number}
     */
    calculateAmount() {
        if(this.props.serves === this.props.origServes) {
            return this.props.data.amount;
        }
        else {
            return this.props.data.amount / this.props.origServes * this.props.serves;
        }
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann 
     * @created 2019-09-06
     * 
     * @return {JSX}
     */
    render() {
        let sMoved = '';
        if(this.state.onShoppingList === true) {
            sMoved = 'moved';
        }
        return (
            <div className="ingredient-container" >
                <div className={'ingredient ' + sMoved} onTouchStart={this.dragElement} onMouseDown={this.dragElement}>
                    <div className="name">{this.props.data.name}</div>
                    <span className="amount">{this.calculateAmount()}</span>
                    <span className="unit">{getUnit(this.props.data.unit)}</span>
                    {this.displayStock()}
                </div>
                {this.state.addError === true ? this.state.addErrorMessage : ''}

                <div className={"loading-container" + (this.state.moving === false ? ' hidden' : '')}>
                    <div className="inner">
                        <span className="fas fa-spinner fa-pulse"></span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Ingredient;
