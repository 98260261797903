import React from 'react';
import {Link} from 'react-router-dom';

// import {log} from '../util/Log';
import {ajax} from '../util/Ajax';
import Authenticator from '../util/Authenticator';

class LoginForm extends React.Component {   
    
    constructor(props) {
        super(props);

        this.state = {
            loggedIn   : false,
            login      : !this.props.register,
            name       : '',
            email      : '',
            password   : '',
            rememberme : false,
            tos        : false
        };

        this.submitForm = this.submitForm.bind(this);

        if(Authenticator.isAuthenticated() === true) {
            this.props.pushHistory('/einkaufsliste');
        }
    }


    /**
     * Validate inputs.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-04
     * 
     * @return {bool}
     */
    validateInputs() {
        let bValid = true;

        // Check if name is filled out
        if(this.state.login !== true
        && this.state.name.trim() === '') {

            this.setState({
                nameError : true,
                nameErrorMessage : 'Bitte gib einen Namen an.'
            });

            bValid = false;
        }

        // Check if email is too long
        if(this.state.login !== true
        && this.state.name.length > 100) {

            this.setState({
                nameError : true,
                nameErrorMessage : 'Dein Name darf maximal 100 Zeichen lang sein.'
            });

            bValid = false;
        }

        // Check if email is filled out
        if(this.state.email.trim() === '') {

            this.setState({
                emailError : true,
                emailErrorMessage : 'Bitte gib eine Email an.'
            });

            bValid = false;
        }

        // Check if email is too long
        if(this.state.email.length > 100) {

            this.setState({
                emailError : true,
                emailErrorMessage : 'Deine Email darf maximal 100 Zeichen lang sein.'
            });

            bValid = false;
        }
        
        // Check if password is long enough
        if(this.state.password.length <= 7) {
            this.setState({
                passwordError : true,
                passwordErrorMessage : 'Dein Passwort muss mindestens 8 Zeichen lang sein.'
            });
            bValid =  false;
        }

        // Check if password is too long
        if(this.state.password.length <= 7) {
            this.setState({
                passwordError : true,
                passwordErrorMessage : 'Dein Passwort darf maximal 100 Zeichen lang sein.'
            });
            bValid =  false;
        }

        if(this.state.login === false
        && this.state.tos === false) {
            this.setState({
                tosError : true,
                tosErrorMessage : 'Bitte akzeptier unsere AGB und Datenschutzbestimmungen.'
            });
            bValid =  false;
        }

        return bValid;
    }


    /**
     * Reset errors.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-13
     */
    resetErrors() {
        this.setState({
            nameError            : false,
            nameErrorMessage     : '',
            emailError           : false,
            emailErrorMessage    : '',
            passwordError        : false,
            passwordErrorMessage : '',
            tosError             : false,
            tosErrorMessage      : ''
        });
    }


    /**
     * Submit.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-06
     */
    submitForm(oEvent) {
        oEvent.preventDefault();

        this.resetErrors();
        
        if(false === this.validateInputs()) {
            return;
        }

        const self  = this;
        const sUrl  = 'user';
        let sAction = 'login';
        const oFormData = new FormData();

        if(false === this.state.login) {
            sAction = 'register';
            oFormData.append('name', this.state.name);
            oFormData.append('tos', this.state.tos);
        }
        else {
            oFormData.append('rememberme', this.state.rememberme);
        }

        oFormData.append('action', sAction);
        oFormData.append('email', this.state.email);
        oFormData.append('password', this.state.password);

        ajax(oFormData, sUrl)
        .then(function(oResponse) {
            if(oResponse.status === 'success') {
                Authenticator.login(oResponse.data);
                self.props.pushHistory('/einkaufsliste');
            }
            else {
                self.setState({
                    error: true,
                    errrorMessage : oResponse.message
                });
            }
        });
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-12
     * 
     * @return {JSX}
     */
    render() {
        return (
            <div className={"login-form " + (this.props.classes)}>

                <div className="tabs tab-container">
                    <ul>
                        <li className={'login' + (this.state.login ? ' active' : '')}>
                            <button onClick={oEvent => this.setState({login: true})}>Anmelden</button>
                        </li>
                        <li className={'register' + (this.state.login ? '' : ' active')}>
                            <button onClick={oEvent => this.setState({login: false})}>Registrieren</button>
                        </li>
                    </ul>
                </div>

                <form onSubmit={this.submitForm}>

                    {this.state.login === false ? 
                        <div className="field">
                            <label className="label" htmlFor="name">Name</label>
                            <p className="control has-icons-left">
                                <input 
                                    id="name" 
                                    className="input"
                                    name="name" 
                                    type="text" 
                                    value={this.state.name} 
                                    onChange={oEvent => this.setState({name: oEvent.target.value})} 
                                    maxLength="100"
                                    required
                                />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-user"></i>
                                </span>
                            </p>
                            {this.state.nameError === true ? 
                                <p className="help is-danger">{this.state.nameErrorMessage}</p>
                                : ''
                            }
                        </div>
                        : ''
                    }

                    <div className="field">
                        <label className="label" htmlFor="email">Email</label>
                        <p className="control has-icons-left">
                            <input 
                                id="email" 
                                className="input"
                                name="email" 
                                type="email" 
                                value={this.state.email} 
                                onChange={oEvent => this.setState({email: oEvent.target.value})}
                                maxLength="100"
                                required
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                            </span>
                        </p>
                        {this.state.emailError === true ? 
                            <p className="help is-danger">{this.state.emailErrorMessage}</p>
                            : ''
                        }
                    </div>

                    <div className="field">
                        <label className="label" htmlFor="password">Passwort</label>
                        <p className="control has-icons-left">
                            <input 
                                id="password" 
                                className="input"
                                name="password" 
                                type="password" 
                                value={this.state.password} 
                                onChange={oEvent => this.setState({password: oEvent.target.value})} 
                                maxLength="100"
                                required
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-lock"></i>
                            </span>
                        </p>
                        {this.state.passwordError === true ? 
                            <p className="help is-danger">{this.state.passwordErrorMessage}</p>
                            : ''
                        }
                    </div>

                    {this.state.login === true ? 
                        <div className="field">

                            <label className="checkbox" htmlFor="rememberme">
                                <input 
                                    id="rememberme" 
                                    className="checkbox-input"
                                    name="rememberme" 
                                    type="checkbox" 
                                    value={this.state.rememberme} 
                                    onChange={oEvent => this.setState({rememberme: !this.state.rememberme})}                            
                                />
                                Angemeldet bleiben
                            </label>
                        </div>
                        : 
                        // TODO agb und datenschutzbestimmungen
                        <div className="field">
                            <label className="checkbox" htmlFor="tos">
                                <input 
                                    id="tos" 
                                    className="checkbox-input"
                                    name="tos" 
                                    type="checkbox" 
                                    value={this.state.tos} 
                                    onChange={oEvent => this.setState({tos: !this.state.tos})}
                                    required
                                />
                                Ich akzeptiere die <Link to="/agb">AGB</Link> und <Link to="/datenschutz">Datenschutz&shy;bestimmungen</Link>.
                            </label>
                            {this.state.tosError === true ? 
                                <p className="help is-danger">{this.state.tosErrorMessage}</p>
                                : ''
                            }
                        </div>                        
                    }

                    {this.state.error === true ? 
                        <p className="help is-danger">{this.state.errrorMessage}</p>
                        : ''
                    }

                    <div className="field">
                        <input className="button is-link is-pulled-right" type="submit" value={this.state.login ? 'Anmelden' : 'Registrieren'} />
                    </div>
                </form>
            </div>
        );
    }
}

export default LoginForm;
