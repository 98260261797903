
/**
 * Authenticator class.
 * 
 * @author Lennart Bergmann <mail@lennartbergmann.com>
 * @created 2020-03-08
 */
class Authenticator {   

    /**
     * Constructor.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * 
     * @created 2020-03-08
     */
    constructor() {
        this.bAuthenticated = false;
        this.sRoomId = '';
        this.sUserId = '';
    }


    /**
     * Returns whether user is authenticated.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-03-08
     * 
     * @returns {bool}
     */
    isAuthenticated() {
        return this.bAuthenticated;
    }


    /**
     * Login
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-03-08
     * 
     * @returns {undefined}
     */
    login(oData) {
        this.bAuthenticated = true;
        this.sRoomId = oData.room_id;
        this.sUserId = oData.user_id;
    }


    /**
     * Logout.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-03-08
     * 
     * @returns {undefined}
     */
    logout() {
        this.bAuthenticated = false;
        this.sRoomId = '';
        this.sUserId = '';
    }


    /**
     * Returns the user id.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-03-08
     * 
     * @returns {string}
     */
    getUserId() {
        return this.sUserId;
    }


    /**
     * Returns the room id.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2020-03-08
     * 
     * @returns {string}
     */
    getRoomId() {
        return this.sRoomId;
    }
    
}

/* Make it Singleton. */
const oInstance = new Authenticator();

export default oInstance;