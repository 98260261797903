import React from 'react';

import {ajax} from '../util/Ajax';
import {log} from '../util/Log';

import Page from './Page';
import UnitSelect from '../partial/UnitSelect';
import AutoComplete from '../partial/AutoComplete';
import OptionalItemFormInputs from '../partial/OptionalItemFormInputs';
import { isNull } from '../util/IsNull';

/**
 * NTH gewürze wie ingredients ODER "das hast du bestimmt zuhause"
 * NTH vorschaubild
 */
class RecipeForm extends React.Component {    

    /**
     * Constructor.
     * 
     * @author Lennart Bergmann 
     * @created 2019-09-08
     * @updated 2020-06-30
     * 
     */
    constructor(props) {
        super(props);

        this.state = {
            title         : '',
            image         : '',
            imageTitle    : 'Bild hochladen',
            imageSrc      : '',
            imageTooLarge : false,
            ingredients   : [{name: '', amount: '', unit: 'g'}],
            serves        : 1,
            calories      : '',
            tags          : '',
            difficulty    : '',
            time          : '',
            directions    : [{text: ''}],
            recipeId      : false,
            loading       : true,
            error         : false,
            errorMessage  : '',
            submitting    : false,
            visibility    : 'public',
            rights        : false
        };
        
        if(this.props.action === 'edit') {
            this.getRecipeData();
        }
        else {
            this.state.loading = false;
        }

        this.displayIngredientInputs = this.displayIngredientInputs.bind(this);
        this.setIngredient           = this.setIngredient.bind(this);
        this.addIngredient           = this.addIngredient.bind(this);
        this.removeIngredient        = this.removeIngredient.bind(this);
        
        this.addDirection    = this.addDirection.bind(this);
        this.removeDirection = this.removeDirection.bind(this);
        this.setImage        = this.setImage.bind(this);
        this.removeImage     = this.removeImage.bind(this);
        this.submit          = this.submit.bind(this);
    }


    /**
     * Component did mount.
     * 
     * @author Lennart Bergmann
     * @created 2021-01-07
     */
    componentDidMount() {
        if(this.props.action === 'edit') {
            this.getRecipeData();
        }
        else {
            this.setState({
                loading: false
            });
        }
    }


    /**
     * Get Recipe Data.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-28
     * @updated 2020-06-30
     * 
     */
    getRecipeData() {
        const self = this;
        const oFormData = new FormData();
        oFormData.append('action', 'get-recipe-data');
        oFormData.append('url', this.props.match.params.url);

        ajax(oFormData, 'recipes')
        .then(function(oResponse) {
            if(oResponse.status === 'success') {
                log(oResponse, 'RecipeForm');
                self.setState({
                    recipeId    : oResponse.data.recipe.id,
                    title       : oResponse.data.recipe.title,
                    imageSrc    : isNull(oResponse.data.recipe.image),
                    serves      : isNull(oResponse.data.recipe.serves),
                    calories    : isNull(oResponse.data.recipe.calories),
                    tags        : isNull(oResponse.data.recipe.tags),
                    directions  : oResponse.data.directions,
                    ingredients : oResponse.data.ingredients,
                    time        : isNull(oResponse.data.recipe.time),
                    difficulty  : isNull(oResponse.data.recipe.difficulty),
                    visibility  : oResponse.data.recipe.visibility,
                    rights      : oResponse.data.recipe.rights,
                    loading     : false
                });
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    loading      : false
                });
            }
        });
    }


    /**
     * Add ingredientinputs.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-08
     * 
     * @param {object} oEvent
     * @param {int} iIndex
     * @param {string} sType
     */
    setIngredient(oEvent, iIndex, sType) {
        log('set ingredient', 'RecipeForm');

        let aIngredients = this.state.ingredients;

        aIngredients[iIndex][sType] = oEvent.target.value;

        this.setState({
            ingredients: aIngredients
        });
    }


    /**
     * Set ingredient from optional input.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-03
     * 
     * @param {int} iIndex
     * @param {object} oItem
     * 
     * @returns {undefined}
     */
    setIngredientOptionalInput = (iIndex, oItem) => {
        let aIngredients = this.state.ingredients;
        let sType = '';
        for(sType in oItem) {
            aIngredients[iIndex][sType] = oItem[sType];
        }

        this.setState({
            ingredients: aIngredients
        });
    }


    /**
     * Set autocomplete item.
     * 
     * @author Lennart Bergmann
     * @created 2020-05-24
     * @updated 2020-06-07
     * 
     * @param {int} iIndex
     * @param {object} oItem
     * 
     * @returns {undefined}
     */
    setItem = (iIndex, oItem) => {
        log(oItem, 'setItem');

        let aIngredients = this.state.ingredients;
        aIngredients[iIndex]['name'] = oItem.product;
        aIngredients[iIndex]['category'] = oItem.category;
        aIngredients[iIndex]['type'] = oItem.type;
        aIngredients[iIndex]['aggregate'] = oItem.aggregate;
        aIngredients[iIndex]['special'] = oItem.special;

        this.setState({
            ingredients: aIngredients
        });
    }


    /**
     * Display ingredientinputs.
     * 
     * @author Lennart Bergmann
     * @created 2019-09-08
     * @updated 2021-01-09
     * 
     * @return {JSX}
     */
    displayIngredientInputs() {
        let aIngredients = [];

        for(let i = 0; i < this.state.ingredients.length; i++) {

            if(this.state.ingredients[i].name === null) {
                continue;
            }

            aIngredients.push(
                <div className="ingredient-container" key={'fragment-' + i}>
                    <div className="field" >
                        <div className="control">
                            <label className="label">Zutat</label>
                            <div className="ingredient-input-container">
                                <AutoComplete 
                                    key={'ingredient-name-' + i} 
                                    setItem={this.setItem.bind(this, i)} 
                                    product={this.state.ingredients[i].name} 
                                    autoFocus={false}
                                />
                                <span className="delete" onClick={() => this.removeIngredient(i)}></span>
                            </div>
                            {this.state.ingredients[i].nameErrMessage !== '' ? 
                                <p className="help is-danger">{this.state.ingredients[i].nameErrMessage}</p> 
                                : ''
                            }
                        </div>
                    </div>

                    <div className="field is-grouped">
                        <div className="control is-expanded">
                            <input 
                                key={'ingredient-amount-' + i} 
                                className="input ingredient-amount" 
                                type="number" 
                                step="0.01"
                                value={this.state.ingredients[i].amount} 
                                placeholder="Menge" 
                                onChange={oEvent => this.setIngredient(oEvent, i, 'amount')} 
                                max="9999"
                            />
                            {this.state.ingredients[i].amountErrMessage !== '' ? 
                                <p className="help is-danger">{this.state.ingredients[i].amountErrMessage}</p> 
                                : ''
                            }
                        </div>

                        <div className="control">
                            <div className="select">
                                <UnitSelect 
                                    id="unit"
                                    value={this.state.ingredients[i].unit}
                                    onChange={oEvent => this.setIngredient(oEvent, i, 'unit')}
                                />
                            </div>
                        </div>
                    </div>

                    <OptionalItemFormInputs 
                        category  = {this.state.ingredients[i]['category']}
                        type      = {this.state.ingredients[i]['type']}
                        aggregate = {this.state.ingredients[i]['aggregate']}
                        special   = {this.state.ingredients[i]['special']}
                        setState  = {this.setIngredientOptionalInput.bind(this, i)}
                        show      = {false}
                    />

                </div>
            );
        }

        return aIngredients;
    }


    /**
     * Set direction input.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-28
     * 
     */
    setDirection(oEvent, iIndex) {
        log('set direction', 'RecipeForm');

        let aDirections = this.state.directions;

        aDirections[iIndex]['text'] = oEvent.target.value;

        this.setState({
            directions: aDirections
        });
    }


    /**
     * Display ingredientinputs.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-08
     * 
     * @return {JSX}
     */
    displayDirectionInputs() {
        let aDirections = [];

        for(let i = 0; i < this.state.directions.length; i++) {
            if(this.state.directions[i].text === null) {
                continue;
            }
            aDirections.push(
                <div className="field direction-container" key={'fragment-' + i}>
                    <div>
                        <textarea 
                            className="textarea" 
                            id="directions" 
                            value={this.state.directions[i].text} 
                            onChange={oEvent => this.setDirection(oEvent, i)}
                            placeholder={'Schritt ' + (i + 1)}
                            maxLength="900"
                        >
                        </textarea>
                        <span className="delete" onClick={() => this.removeDirection(i)}></span>
                    </div>
                    {this.state.directions[i].nameErrMessage !== '' ? 
                        <p className="help is-danger">{this.state.directions[i].errMessage}</p> 
                        : ''
                    }
                </div>
            );
        }


        return aDirections;
    }


    /**
     * Add ingredientinputs.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-08
     * 
     */
    addIngredient() {
        let aIngredients = this.state.ingredients;
        aIngredients.push({name: '', amount: '', unit: 'g'});

        this.setState({
            ingredients: aIngredients
        });
    }


    /**
     * Remove ingredient inputs.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-02
     * 
     */
    removeIngredient(iIndex) {
        log(iIndex, 'RecipeForm');

        let aIngredients = this.state.ingredients;

        aIngredients[iIndex]['name'] = null;
        aIngredients[iIndex]['amount'] = '';
        aIngredients[iIndex]['unit'] = '';

        this.setState({
            ingredients: aIngredients
        });
    }


    /**
     * Add direction inputs.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-28
     * 
     */
    addDirection() {
        let aDirections = this.state.directions;
        aDirections.push({text: ''});

        this.setState({
            directions: aDirections
        });
    }


    /**
     * Remove direction inputs.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-02
     * 
     */
    removeDirection(iIndex) {
        log('remove direction', 'RecipeForm');

        let aDirections = this.state.directions;

        aDirections[iIndex]['text'] = null;

        this.setState({
            directions: aDirections
        });
    }


    /**
     * Validate inputs.
     * TODO validate optional inputs
     * 
     * @author Lennart Bergmann
     * @created 2019-10-04
     * @updated 2020-06-29
     */
    validateInputs() {
        let bValid = true;

        // Check if title is empty
        if(this.state.title.trim() === '') {
            this.setState({
                titleErrMessage : 'Der Titel darf nicht leer sein.'
            });

            bValid = false;
        }

        // Check if title is too long
        if(this.state.title.length > 100) {
            this.setState({
                titleErrMessage : 'Der Titel darf maximal 100 Zeichen lang sein.'
            });

            bValid = false;
        }

        // Check ingredients
        let aIngredients = this.state.ingredients;
        for(let i = 0; i < aIngredients.length; i++) {
            if(aIngredients[i].name === null) {
                continue;
            }

            if(aIngredients[i].name.length > 100) {
                aIngredients[i].nameErrMessage = 'Zutaten dürfen maximal 100 Zeichen lang sein.';
                bValid = false;
            }

            if(aIngredients[i].amount.length > 100) {
                aIngredients[i].amountErrMessage = 'Mengen dürfen maximal 100 Zeichen lang sein.';
                bValid = false;
            }

            if(true === isNaN(aIngredients[i].amount)) {
                aIngredients[i].amountErrMessage = 'Menge muss eine Zahl sein.';
                bValid = false;
            }
        }

        // Check directions
        let aDirections = this.state.directions;
        for(let i = 0; i < aDirections.length; i++) {
            if(aDirections[i].text === null) {
                continue;
            }

            if(aDirections[i].text.length > 1000) {
                aDirections[i].errMessage = 'Anleitungsschritte dürfen maximal 1000 Zeichen lang sein.';
                bValid = false;
            }
        }

        // Set errors
        this.setState({
            ingredients : aIngredients,
            directions  : aDirections
        });

        // Check if serves is too long
        if(this.state.serves.length > 3) {
            this.setState({
                servesErrMessage : 'Bitte gib maximal 3 Zeichen ein.'
            });
            bValid = false;
        }

        // Check if serves is integer
        if(this.state.serves !== ''
        && true === isNaN(this.state.serves)) {
            this.setState({
                servesErrMessage : 'Bitte gib eine Zahl ein.'
            });
            bValid = false;
        }

        // Check if cals is too long
        if(this.state.calories
        && this.state.calories.length > 5) {
            this.setState({
                calsErrMessage : 'Bitte gib maximal 5 Zeichen ein.'
            });
            bValid = false;
        }

        // Check if cals is integer
        if(this.state.calories
        && true === isNaN(this.state.calories)) {
            this.setState({
                calsErrMessage : 'Bitte gib eine Zahl ein.'
            });
            bValid = false;
        }

        // Check if time is too long
        if(null !== this.state.time && this.state.time.length > 10) {
            this.setState({
                timeErrMessage : 'Bitte gib maximal 10 Zeichen ein.'
            });
            bValid = false;
        }

        // Check if time is integer
        if(this.state.time
        && true === isNaN(this.state.time)) {
            this.setState({
                timeErrMessage : 'Bitte gib eine Zahl ein.'
            });
            bValid = false;
        }

        // Check if difficulty is allowed choice
        console.log(this.state.difficulty);
        if(this.state.difficulty
        && this.state.difficulty !== 'easy'
        && this.state.difficulty !== 'medium'
        && this.state.difficulty !== 'hard') {
            console.log(this.state.difficulty);
            console.log(null);
            this.setState({
                difficultyErrMessage : 'Error.'
            });
            bValid = false;
        }

        // Check if tags is too long
        if(this.state.tags
        && this.state.tags.length > 100) {
            this.setState({
                tagsErrMessage : 'Bitte gib maximal 100 Zeichen ein.'
            });
            bValid = false;
        }

        return bValid;
    }


    /**
     * Submit.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-08
     * @updated 2020-06-29
     * 
     * @param {object} oEvent
     */
    submit(oEvent) {
        oEvent.preventDefault();
        const self = this;
        log(this.state, 'RecipeForm');

        this.setState({
            sumitting: true
        });

        // Validate inputs
        if(false === this.validateInputs()) {
            return;
        }

        let sAction = 'new-recipe';
        if(this.props.action === 'edit') {
            sAction = 'edit-recipe';
        }

        const oFormData = new FormData();
        oFormData.append('action', sAction);
        oFormData.append('title', this.state.title);
        oFormData.append('serves', this.state.serves);
        oFormData.append('calories', this.state.calories);
        oFormData.append('time', this.state.time);
        oFormData.append('difficulty', this.state.difficulty);
        oFormData.append('tags', this.state.tags);

        oFormData.append('visibility', this.state.visibility);
        oFormData.append('rights', this.state.rights);

        oFormData.append('ingredients', JSON.stringify(this.state.ingredients));     
        oFormData.append('directions', JSON.stringify(this.state.directions));

        if(this.props.action === 'edit'
        && this.state.imageSrc !== ''
        && this.state.image === '') {
            oFormData.append('imageSrc', this.state.imageSrc);
        }
        else {
            const oInput = document.querySelector('input[type="file"]');
            oFormData.append('image', oInput.files[0]);
        }


        if(this.state.recipeId !== false) {
            oFormData.append('id', this.state.recipeId);
        }

        ajax(oFormData, 'recipes')
        .then(function(oResponse) {
            log(oResponse, 'RecipeForm');

            self.unsetErrors(); 

            if(oResponse.status === 'success') {
                if(self.props.action === 'edit') {
                    self.props.history.push('/rezept/' + self.props.match.params.url);
                }
                else {
                    self.props.history.push('/rezept/' + oResponse.data.url);
                }
            }
            else if(oResponse.status === 'error' && oResponse.hasOwnProperty('data')) {
                oResponse.data.forEach(oError => {
                    let i = 0;
                    for(i in oError) {
                        if('ingredients' === i) {
                            let aIngredients = self.state.ingredients;
                            aIngredients[oError[i].index][oError[i].state] = oError[i].message;
                            self.setState({
                                ingredients : aIngredients
                            });
                        }
                        else if('directions' === i) {
                            let aDirections = self.state.directions;
                            aDirections[oError[i].index][oError[i].state] = oError[i].message;
                            self.setState({
                                directions : aDirections
                            });
                        }
                        else {
                            self.setState({ [i]: oError[i] });
                        }
                    }
                });                
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message,
                    submitting   : false
                });
            }
            
        });
    }


    /**
     * Unsets all errors.
     * 
     * @author Lennart Bergmann
     * @created 2020-03-09
     * 
     * @returns {undefined}
     */
    unsetErrors() {
        let aIngredients = this.state.ingredients;
        let aDirections = this.state.directions;

        aIngredients.forEach(oElem => {
            if(oElem['nameErrMessage']) {
                oElem['nameErrMessage'] = '';
            }
            if(oElem['amountErrMessage']) {
                oElem['amountErrMessage'] = '';
            }
        });

        aDirections.forEach(oElem => {
            if(oElem['errMessage']) {
                oElem['errMessage'] = '';
            }
        });

        this.setState({
            titleErrMessage  : '',
            imageErrMessage  : '',
            servesErrMessage : '',
            calsErrMessage   : '',
            timeErrMessage   : '',
            tagsErrMessage   : '',
            difficultyErrMessage : '',
            ingredients : aIngredients,
            directions  : aDirections
        });
    }


    /**
     * Displays image in edit mode.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-28
     * 
     * @return {JSX}
     */
    displayImage() {
        if(this.state.imageSrc !== ''
        && this.state.image === '') {
            return (
                <div className="field image-container">
                    <picture>
                        <source srcSet={process.env.REACT_APP_UPLOADS_URL + this.state.imageSrc + '_L.jpg'} media="(min-width: 768px)" />
                        <source srcSet={process.env.REACT_APP_UPLOADS_URL + this.state.imageSrc + '_M.jpg'} media="(min-width: 425px)" />
                        <img src={process.env.REACT_APP_UPLOADS_URL + this.state.imageSrc + '_S.jpg'} alt="Rezept" />
                    </picture>
                    <span className="delete" onClick={this.removeImage}></span>
                </div>
            );
        }

        return '';
    }


    /**
     * Set image.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-12
     * 
     * @param {object} oEvent
     */
    setImage(oEvent) {
        const iMaxFileSize = 1024 * 1024 * process.env.REACT_APP_MAX_FILE_SIZE;
        let bTooLarge = false;

        if(oEvent.target.files[0].size > iMaxFileSize) {
            bTooLarge = true;
        }

        this.setState({
            image         : oEvent.target.value,
            imageTitle    : oEvent.target.files[0].name,
            imageTooLarge : bTooLarge
        });
    }


    /**
     * Remove image.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-10-12
     */
    removeImage() {
        this.setState({
            image         : '',
            imageSrc      : '',
            imageTitle    : 'Bild hochladen',
            imageTooLarge : false
        });
    }


    /**
     * Returns to recipe overview.
     * 
     * @author Lennart Bergmann
     * @created 2020-03-08
     * 
     * @returns {undefined}
     */
    cancel() {
        this.props.history.push('/recipes');
    }


    /**
     * Display the rights checkobx.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-28
     * 
     * @returns {JSX}
     */
    displayCheckbox = () => {
        return (
            <div className="field rights-checkbox">
                <div className="control">
                    <label className="checkbox">
                        <input type="checkbox" checked={this.state.rights} onChange={oEvent => this.setState({rights : !this.state.rights})} required /> 
                        Ich habe die Rechte an Bild und Text von diesem Rezept.
                    </label>
                </div>
            </div>
        );
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-08
     * @updated 2021-01-09
     * 
     * @return {JSX}
     */
    render() {
        return (
            <Page
                class = 'recipe-form'
                error = {this.state.error} 
                loading = {this.state.loading}
                headline = {'edit' === this.props.action ? 'Rezept bearbeiten' : 'Neues Rezept'} 
                title = {'edit' === this.props.action ? this.state.title + ' bearbeiten' : 'Neues Rezept'} 
                errorMessage = {this.state.errorMessage}
                currentMenuItem = '/rezepte'
                >
                <div className="form-container">
                    <form onSubmit={this.submit}>
                        <div className="field">
                            <label className="label" htmlFor="title">Titel:</label>
                            <input type="text" className="input" id="title" value={this.state.title} onChange={oEvent => this.setState({title: oEvent.target.value})} maxLength="100" autoFocus={true} />
                            {this.state.titleErrMessage !== '' ? <p className="help is-danger">{this.state.titleErrMessage}</p> : ''}

                        </div>

                        <div className="field file-input-container">
                            <div className="file">
                                <label className="file-label" htmlFor="image">
                                    <input 
                                        id="image"
                                        className="file-input image" 
                                        type="file" 
                                        accept=".jpg,.png" 
                                        value={this.state.image}
                                        onChange={this.setImage} 
                                    />
                                    <span className="file-cta">
                                        <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                        </span>
                                        <span className="file-label">
                                            {this.state.imageTitle}                                            
                                        </span>
                                    </span>
                                </label>

                                {this.state.image !== '' ? 
                                    <span className="delete" onClick={() => this.removeImage()}></span>
                                    : ''
                                }
                                
                            </div>
                            {this.state.imageErrMessage !== '' ? <p className="help is-danger">{this.state.imageErrMessage}</p> : ''}                            

                            {this.state.imageTooLarge === true ? 
                                <p className="help is-danger">Das Bild ist zu groß, erlaubt sind maximal {process.env.REACT_APP_MAX_FILE_SIZE}MB.</p>
                                : ''    
                            }

                        </div>

                        {this.displayImage()}

                        {this.displayIngredientInputs()}

                        <div className="field">
                            <input type="button" className="button is-info" value="Zutat hinzufügen" onClick={this.addIngredient} />
                        </div>

                        <div className="field directions-container">
                            <label className="label" htmlFor="directions">Anleitung:</label>
                            {this.displayDirectionInputs()}
                        </div>

                        <div className="field">
                            <input type="button" className="button is-info" value="Schritt hinzufügen" onClick={this.addDirection} />
                        </div>

                        <div className="field serves-container">
                            <label className="label" htmlFor="serves">Portionen (Optional)</label>
                            <input type="number" className="input" id="serves" value={this.state.serves} onChange={oEvent => this.setState({serves: oEvent.target.value})} step="1" max="999" />
                            {this.state.servesErrMessage !== '' ? <p className="help is-danger">{this.state.servesErrMessage}</p> : ''}
                        </div>

                        <div className="field">
                            <label className="label" htmlFor="calories">Kalorien pro Portion (Optional)</label>
                            <input type="number" className="input" id="calories" value={this.state.calories} onChange={oEvent => this.setState({calories: oEvent.target.value})} step="1" max="9999" />
                            {this.state.calsErrMessage !== '' ? <p className="help is-danger">{this.state.calsErrMessage}</p> : ''}
                        </div>

                        <div className="field">
                            <label htmlFor="time" className="label">Zeitaufwand in Minuten (Optional)</label>
                            <input type="number" className="input" id="time" value={this.state.time} onChange={oEvent => this.setState({time: oEvent.target.value})} step="1" max="999" />                            
                            {this.state.timeErrMessage !== '' ? <p className="help is-danger">{this.state.timeErrMessage}</p> : ''}
                        </div> 

                        <div className="field">
                            <label htmlFor="difficulty" className="label">Schwierigkeit (Optional)</label>
                            <div className="select">
                                <select id="difficulty" value={this.state.difficulty} onChange={oEvent => this.setState({difficulty: oEvent.target.value})}>
                                    <option value="">Bitte wählen</option>
                                    <option value="easy">Einfach</option>
                                    <option value="medium">Mittel</option>
                                    <option value="hard">Schwierig</option>
                                </select>
                            </div>
                            {this.state.difficultyErrMessage !== '' ? <p className="help is-danger">{this.state.difficultyErrMessage}</p> : ''}
                        </div> 

                        <div className="field">
                            <label htmlFor="tags" className="label">Tags (Optional)</label>
                            <input type="text" className="input" id="tags" value={this.state.tags} onChange={oEvent => this.setState({tags: oEvent.target.value})} maxLength="9999" />
                            <p className="help">Mehrere Tags mit einem Komma trennen.</p>
                            {this.state.tagsErrMessage !== '' ? <p className="help is-danger">{this.state.tagsErrMessage}</p> : ''}
                        </div>

                        <div className="field">
                            <label htmlFor="visibility" className="label">Sichtbarkeit</label>
                            <div className="select">
                                <select id="visibility" value={this.state.visibility} onChange={oEvent => this.setState({visibility: oEvent.target.value})}>
                                    <option value="public">Öffentlich</option>
                                    <option value="private">Privat</option>
                                </select>
                            </div>
                            {'public' === this.state.visibility ? 
                                this.displayCheckbox()
                                : <p className="help">Nur du und Benutzer in deinem Raum können dieses Rezept sehen.</p>
                            }
                        </div> 
    
                        <div className="field is-grouped is-grouped-right">
                            <div className="control">
                                <button className="button btn-cancel" formNoValidate onClick={this.cancel.bind(this)}>Abbrechen</button>
                            </div>
                            <div className="control">
                                <button className={"button is-link" + (true === this.state.submitting ? ' is-loading' : '')}>
                                    Speichern
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Page>
        );
        

    }
}

export default RecipeForm;
