import React from 'react';
import {Link} from 'react-router-dom';

import Page from './Page';

/**
 * 404 Not found.
 * 
 * @author Lennart Bergmann
 * @created 2020-12-31
 * @updated 2021-01-02
 */
class NotFound extends React.Component 
{
    /**
     * Render.
     * 
     * @author Lennart Bergmann
     * @created 2020-12-31
     * @updated 2021-01-02
     * 
     * @return {JSX}
     */
    render() {
        return (
            <Page 
                class    = {"not-found"}
                title    = {"404 - Diese Seite existiert nicht"}
                headline = {"404"}
                >
                <div>
                    Diese Seite gibt's gar nicht! Klick <Link to="/">hier</Link> um zur Startseite zu gelangen.
                </div>
            </Page>
        );
    }
}

export default NotFound;
