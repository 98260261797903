
/**
 * Removes whitespace from the
 * beginning and end of the string.
 * 
 * @author Lennart Bergmann <mail@lennartbergmann.com>
 * @created 2020-03-22
 * 
 * @param {string} sString
 */
function trim(sString) {
    sString = sString.replace(/^\s+/g, '');
    return sString.replace(/\s+$/g, '');
}

export { trim };