import React from 'react';
import {Link} from 'react-router-dom';

const Menu = props => {    

    /**
     * Display given menu item.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-07
     * @updated 2020-04-19
     * 
     * @param {string} sClasses
     * @param {string} sRoute
     * @param {string} sName
     * 
     * @return {JSX}
     */
    const displayMenuItem = (sIcon, sRoute, sName) => {
        let sClasses = 'menu-item';
        let sLinkClass = ''

        if(props.current === sRoute) {
            sLinkClass += 'current';
        }

        return (
            <div className={sClasses}>
                <Link to={sRoute} className={sLinkClass}>
                    <div>
                        <span className="icon  has-text-black">
                            <i className={"fas fa-lg fa-" + sIcon}></i>
                        </span>
                        {/* <div>{sName}</div> */}
                    </div>
                </Link>
            </div>
        );
    };


    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-06
     * @updated 2020-04-19
     * 
     * @return {JSX}
     */
    return (
        <div className={"menu-container" + (props.posMenuFixed ? '' : ' scroll')}>
            <div className="inner">
                {displayMenuItem('home', '/vorrat', 'Vorrat')}
                {displayMenuItem('shopping-cart', '/einkaufsliste', 'Einkaufsliste')}
                {displayMenuItem('utensils', '/rezepte', 'Rezepte')}
                {displayMenuItem('cog', '/einstellungen', 'Einstellungen')}
            </div>
        </div>
    );
}

export default Menu;