
/**
 * LocalStorageHelper class.
 * 
 * @author Lennart Bergmann 
 * @created 2020-06-27
 */
class LocalStorageHelper {

    /**
     * Constructor.
     * 
     * @author Lennart Bergmann 
     * 
     * @created 2020-06-27
     */
    constructor() {
        this.bIsEnabled = this.isEnabled();
    }


    /**
     * Returns whether local storage is supported.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-27
     * 
     * @returns {bool}
     */
    isEnabled = () => {
        if('undefined' !== typeof(Storage)) {
            return true;
        }
        return false;
    }


    /**
     * Get value to given key.
     * 
     * @author Lennart Bergmann 
     * @created 2020-06-27
     * 
     * @param {string} sKey
     * 
     * @returns {mixed}
     */
    getItem = sKey => {
        if(this.bIsEnabled) {
            return localStorage.getItem(sKey);
        }
        return null;
    }


    /**
     * Sets item with given key and value.
     * 
     * @author Lennart Bergmann
     * @created 2020-06-27
     * 
     * @param {string} sKey
     * @param {mixed} mValue
     * 
     * @returns {mixed}
     */
    setItem = (sKey, mValue) => {
        if(this.bIsEnabled) {
            return localStorage.setItem(sKey, mValue);
        }
        return null;
    }


    /**
     * Remove item with given key.
     * 
     * @author Lennart Bergmann 
     * @created 2020-06-28
     * 
     * @param {string} sKey
     * 
     * @returns {mixed}
     */
    removeItem = sKey => {
        if(this.bIsEnabled) {
            return localStorage.removeItem(sKey);
        }
        return null;
    }
}

/* Make it Singleton. */
const oInstance = new LocalStorageHelper();

export default oInstance;