import React from 'react';

import {log} from '../util/Log';
import {ajax} from '../util/Ajax';

import Error from './Error';

class RoomUsers extends React.Component {


    /**
     * Constructor.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-26
     */
    constructor(props) {
        super(props);

        this.state = {
            display      : true,
            approved     : this.props.approved !== "0",
            error        : false,
            errorMessage : ''
        };

        this.approveUser = this.approveUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.rejectUser = this.rejectUser.bind(this);
    }


    /**
     * Approve user.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-26
     */
    approveUser() {
        const self = this;
        const sUrl = 'user';
        const sAction = 'approve-user';

        const oFormData = new FormData();
        oFormData.append('action', sAction);
        oFormData.append('email', this.props.email);

        ajax(oFormData, sUrl)
        .then(function(oResponse) {
            log(oResponse, 'RoomUsers');

            if(oResponse.status === 'success') {
                self.setState({
                    approved: true
                });
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message
                });
            }
        });
    }


    /**
     * Reject user from room.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-26
     * 
     * @return {JSX}
     */
    rejectUser() {
        this.removeUser(null, 'ablehnen');
    }


    /**
     * Remove user from room.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-26
     * 
     * @return {JSX}
     */
    removeUser(oEvent, sConfirm = 'entfernen') {
        const bDelete = window.confirm('Willst du diesen Nutzer wirklich ' + sConfirm + '?');

        if(bDelete === false) {
            log('dont remove', 'RoomUser');
            return;
        }        

        log('remove user', 'RoomUser');

        const self = this;
        const sUrl = 'user';
        const sAction = 'remove-user';

        const oFormData = new FormData();
        oFormData.append('action', sAction);
        oFormData.append('email', this.props.email);

        ajax(oFormData, sUrl)
        .then(function(oResponse) {
            log(oResponse, 'RoomUsers');

            if(oResponse.status === 'success') {
                self.setState({
                    display: false
                });
            }
            else {
                self.setState({
                    error        : true,
                    errorMessage : oResponse.message
                });
            }
        });

    }


    /**
     * Display approve/reject buttons.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-26
     * 
     * @return {JSX}
     */
    displayApprove() {
        return (
            <React.Fragment>
                <span className="delete" onClick={this.rejectUser}>Ablehnen</span>
                <span onClick={this.approveUser} className="icon accept">
                    <i className="fas fa-check-circle"></i>
                </span>
            </React.Fragment>
        );
    }


    /**
     * Display remove button.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-26
     * 
     * @return {JSX}
     */
    displayRemove() {
        return (
            <span className="delete" onClick={this.removeUser}>Entfernen</span>
        );
    }


    /**
     * Render.
     * 
     * @author Lennart Bergmann <mail@lennartbergmann.com>
     * @created 2019-09-26
     * 
     * @return {JSX}
     */
    render() {

        if(true === this.state.error) {
            return <Error errorMessage={this.state.errorMessage} />;
        }

        return (
            <div className="room-user-container">
                <div className="button-container">
                    {this.state.approved === true ? this.displayRemove() : this.displayApprove()}
                </div>
                <div className="email-container">{this.props.email}</div>
            </div>
        )
    }
}

export default RoomUsers;
